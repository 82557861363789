<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Titulo interno (No se muestra en App)"
          label-for="vi-titulo"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-titulo"
              v-model="element.titulo"
              placeholder="Titulo"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="vi-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-inicio"
            type="date"
            placeholder="FechaInicio"
            v-model="element.fechaInicio"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-fin"
            type="date"
            placeholder="Fecha Fin"
            v-model="element.fechaFin"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-inicio"
            type="time"
            placeholder="Hora Inicio"
            v-model="element.horaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="vi-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-fin"
            type="time"
            placeholder="Hora Fin"
            v-model="element.horaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
      <b-col cols="12" class="my-2">
        <div
          v-if="element.items[0].loadingImage"
          style="
            height: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <b-spinner style="width: 3rem; height: 3rem" label="Loading..." />
        </div>
        <div v-else>
          <b-img
            v-if="element.items[0].imagen !== null"
            :src="urlResized(element.items[0].imagen)"
            fluid
            alt="Responsive image"
          />
        </div>
      </b-col>

      <b-col cols="12">
        <b-form-group
          label="Imagen [1600 x 900] [Las esquinas redondeadas las coloca el app]"
          label-for="vi-file-in"
        >
          <b-form-file
            id="vi-file-in"
            accept=".jpg, .png"
            @change="encodeImageFile"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <bodegas-sectores
          :bodegas="bodegas"
          :element="element"
          :sectores="sectores"
          :force-main="true"
        />
      </b-col>

      <b-col cols="12">
        <b-button
          type="submit"
          variant="primary"
          class="mr-1 mt-2"
          @click="savedata"
          :disabled="loading"
        >
          <b-spinner v-if="loading" small label="Loading..." />
          <div v-else>Guardar</div>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>


<script>
import {
  BImg,
  BRow,
  BCol,
  BForm,
  BButton,
  BSpinner,
  BFormFile,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BInputGroup,
  BCardHeader,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import BodegasSectores from "./BodegasSectores.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BForm,
    BButton,
    vSelect,
    BSpinner,
    BFormFile,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BInputGroup,
    AppCollapse,
    BCardHeader,
    BFormCheckbox,
    BListGroupItem,
    BodegasSectores,
    BFormRadioGroup,
    AppCollapseItem,
    ToastificationContent,
    BFormDatepicker
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      imageSRC: null,
      tipoFiltrado: "Sectores",
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
      currentDate: new Date(),
    };
  },
  beforeMount() {
    this.element.main = true;
    this.element.comercio = "MARKET";
    this.element.opciones = null;
    if (this.element.items.length === 0)
      this.element.items.push({
        activo: true,
        accion: "NONE",
        loadingImage: false,
      });
    else
      this.element.items.forEach((i) => {
        i.loadingImage = false;
      });
  },
  methods: {
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/600x400" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://trade.hemonline.com/html5/Resources/Themes/Default/English/Images/t2/BodyBanner.jpg";
      }
    },
    encodeImageFile(element) {
      const self = this;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          self.uploadImage(srcData);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    uploadImage(imagenSRC) {
      const self = this;
      self.element.items[0].loadingImage = true;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: imagenSRC,
            folder: 'Banners',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.element.items[0].loadingImage = false;
          if (res.data && res.data.length > 0) {
            self.element.items[0].imagen = res.data[0].url;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          self.element.items[0].loadingImage = false;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    savedata(e) {
      e.preventDefault();
      this.callback(this.element);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>