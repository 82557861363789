<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-form-group label="Titulo" label-for="vi-titulo">
        <b-input-group class="input-group-merge">
          <b-form-input id="vi-titulo" v-model="element.titulo" placeholder="Titulo" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Tipo de Comercio" label-for="vi-tipo-comercio">
        <v-select id="vi-tipo-comercio" label="titulo" v-model="element.comercio" :reduce="(comercio) => comercio.code"
          :options="tiposComercio" />
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="vi-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker :min="currentDate" id="vi-fecha-inicio" type="date" placeholder="FechaInicio"
            v-model="element.fechaInicio" :date-format-options="{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker :min="currentDate" id="vi-fecha-fin" type="date" placeholder="Fecha Fin"
            v-model="element.fechaFin" :date-format-options="{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input id="vi-hora-inicio" type="time" placeholder="Hora Inicio" v-model="element.horaInicio" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="vi-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input id="vi-hora-fin" type="time" placeholder="Hora Fin" v-model="element.horaFin" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <bodegas-sectores :bodegas="bodegas" :element="element" :sectores="sectores" />
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-alert show variant="primary">
        <div class="alert-body">
          <feather-icon class="mr-25" icon="StarIcon" />
          <span class="ml-25">Este elemento genera el contenido dinamicamente a travez de un Job.</span>
        </div>
      </b-alert>
    </b-col>

    <b-col cols="12">
      <b-button class="mr-1 mt-2" @click="savedata" variant="primary" :disabled="loading">
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-col>
  </b-row>
</template>


<script>
import {
  BImg,
  BRow,
  BCol,
  BAlert,
  BButton,
  BSpinner,
  BFormFile,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import BodegasSectores from "./BodegasSectores.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BAlert,
    BButton,
    vSelect,
    BSpinner,
    BFormFile,
    draggable,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BodegasSectores,
    BFormRadioGroup,
    ToastificationContent,
    BFormDatepicker
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      tiendas: [],
      imageSRC: null,
      tiendasOC: [],
      tiendasEats: [],
      tipoFiltrado: "Sectores",
      tiposComercio: [
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
      currentDate: new Date(),
    };
  },
  beforeMount() {
    this.element.items = [];
    this.element.opciones = null;
  },
  methods: {
    savedata(e) {
      e.preventDefault();
      let i = 0;
      this.element.items.forEach((item) => {
        item.orden = i;
        i++;
      });
      this.callback(this.element);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>