var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Titulo",
      "label-for": "vi-titulo"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-titulo",
      "placeholder": "Titulo"
    },
    model: {
      value: _vm.element.titulo,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "titulo", $$v);
      },
      expression: "element.titulo"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de Comercio",
      "label-for": "vi-tipo-comercio"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo-comercio",
      "label": "titulo",
      "reduce": function reduce(comercio) {
        return comercio.code;
      },
      "options": _vm.tiposComercio
    },
    model: {
      value: _vm.element.comercio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "comercio", $$v);
      },
      expression: "element.comercio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "vi-element-fecha-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.getCurrentDate,
      "id": "vi-element-fecha-inicio",
      "local": "es",
      "placeholder": "FechaInicio",
      "date-format-options": {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    },
    model: {
      value: _vm.element.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaInicio", $$v);
      },
      expression: "element.fechaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "vi-element-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.getCurrentDate,
      "id": "vi-element-fecha-fin",
      "type": "date",
      "placeholder": "Fecha Fin",
      "date-format-options": {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    },
    model: {
      value: _vm.element.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaFin", $$v);
      },
      expression: "element.fechaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": "vi-hora-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-hora-inicio",
      "type": "time",
      "placeholder": "Hora Inicio",
      "locale": "en"
    },
    model: {
      value: _vm.element.horaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaInicio", $$v);
      },
      expression: "element.horaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": "vi-hora-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-hora-fin",
      "type": "time",
      "placeholder": "Hora Fin",
      "locale": "en"
    },
    model: {
      value: _vm.element.horaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaFin", $$v);
      },
      expression: "element.horaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Banners")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.agregarItem
    }
  }, [_vm._v(" Nuevo Banner ")])], 1), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.element.items,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "items", $$v);
      },
      expression: "element.items"
    }
  }, [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.element.items, function (item, index) {
    return _c('b-list-group-item', {
      key: index + 1,
      attrs: {
        "tag": "li"
      }
    }, [_c('item-carrusel-banner', {
      attrs: {
        "item": item,
        "element": _vm.element,
        "bodegas": _vm.bodegas,
        "sectores": _vm.sectores,
        "index": index,
        "formType": item.id <= 0 ? 0 : 1,
        "refreshNotification": _vm.refreshNotification
      }
    })], 1);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }