<template>
  <app-collapse>
    <app-collapse-item title="Donde mostrar">
      <b-row>
        <b-col cols="12" md="9">
          <b-form-group label="Donde mostrar" label-for="vi-tipo">
            <b-form-radio-group
              id="vi-tipo"
              buttons
              v-model="tipoFiltrado"
              :options="['Bodegas', 'Sectores']"
              button-variant="outline-primary"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" v-if="!forceMain">
          <b-form-group label="Home Principal" label-for="vi-main">
            <b-form-checkbox
              id="vi-main"
              class="custom-control-primary"
              switch
              v-model="element.main"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" style="display: flex; align-items: start">
          <v-select
            id="vi-tipo-bodegas"
            v-if="tipoFiltrado === 'Bodegas'"
            label="nombre"
            style="flex: 1"
            v-model="element.bodegas"
            multiple
            :options="bodegas"
          />
          <v-select
            id="vi-tipo-sectores"
            label="nombre"
            style="flex: 1"
            v-else
            v-model="element.sectores"
            multiple
            :options="sectores"
          />
          <div style="display: flex; flex-direction: column">
            <b-button class="ml-1 mb-1" @click="selectAll" variant="primary">
              Seleccionar todo
            </b-button>
            <b-button class="ml-1" @click="unselectAll" variant="primary">
              Deseleccionar todo
            </b-button>
          </div>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
</template>
<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormGroup,
    AppCollapse,
    BFormCheckbox,
    AppCollapseItem,
    BFormRadioGroup,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    forceMain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tipoFiltrado: "Sectores",
    };
  },
  beforeMount() {
    if (this.forceMain) this.element.main = true;
  },
  methods: {
    selectAll() {
      if (this.tipoFiltrado === "Sectores")
        this.element.sectores = this.sectores;
      else this.element.bodegas = this.bodegas;
    },
    unselectAll() {
      if (this.tipoFiltrado === "Sectores") this.element.sectores = [];
      else this.element.bodegas = [];
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>