<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-form-group label="Titulo" label-for="vi-titulo">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-titulo"
            v-model="element.titulo"
            placeholder="Titulo"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Tipo de Comercio" label-for="vi-tipo-comercio">
        <v-select
          id="vi-tipo-comercio"
          label="titulo"
          v-model="element.comercio"
          :reduce="(comercio) => comercio.code"
          :options="tiposComercio"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="lhg-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="lhg-fecha-inicio"
            type="date"
            placeholder="Fecha Inicio"
            v-model="element.fechaInicio"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="lhg-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="lhg-fecha-fin"
            type="date"
            placeholder="Fecha Fin"
            v-model="element.fechaFin"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="lhg-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="lhg-hora-inicio"
            type="time"
            placeholder="Hora Inicio"
            v-model="element.horaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="lhg-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="lhg-hora-fin"
            type="time"
            placeholder="Hora Fin"
            v-model="element.horaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-card-header>
        <b-card-title>Banners</b-card-title>
        <b-button variant="primary" class="mr-1" @click="agregarItem">
          Agregar
        </b-button>
      </b-card-header>
      <draggable
        tag="ul"
        class="list-group list-group-flush cursor-move"
        v-model="element.items"
      >
        <transition-group type="transition" name="flip-list">
          <b-list-group-item
            tag="li"
            :key="item.id"
            v-for="(item, index) in element.items"
          >
            <b-row>
              <b-col cols="12" md="4">
                <b-form-radio-group
                  id="vi-style"
                  size="sm"
                  class="my-1"
                  buttons
                  v-model="item.opciones.estilo"
                  :options="[
                    { text: 'Estilo 1', value: 1 },
                    { text: 'Estilo 2', value: 2 },
                  ]"
                  text-field="text"
                  value-field="value"
                  button-variant="outline-primary"
                />
                <div
                  v-if="item.loadingImage"
                  style="
                    height: 70%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <b-spinner
                    style="width: 3rem; height: 3rem"
                    label="Loading..."
                  />
                </div>
                <div v-else>
                  <b-img
                    v-if="item.imagen !== null"
                    :src="urlResized(item.imagen)"
                    fluid
                    alt="Responsive image"
                  />
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Texto" :label-for="'vi-texto' + index">
                  <b-form-input
                    :id="'vi-texto' + index"
                    v-model="item.nombre"
                    placeholder="Texto"
                  />
                </b-form-group>
                <b-form-group
                  :label="dimensionImagen(item)"
                  :label-for="'vi-file-in' + index"
                >
                  <b-form-file
                    :id="'vi-file-in' + index"
                    accept=".jpg, .png"
                    @change="encodeImageFile($event, index)"
                  />
                </b-form-group>
                <acciones :item="item" :comercio="element.comercio" />
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group label="Activo" :label-for="'vi-activo' + index">
                  <b-form-checkbox
                    :id="'vi-activo' + index"
                    class="custom-control-primary"
                    switch
                    v-model="item.activo"
                  />
                </b-form-group>
                <b-form-group label="Borrar" :label-for="'vi-delete' + index">
                  <b-button
                    :id="'vi-delete' + index"
                    class="btn-icon"
                    @click="eliminarItem(index)"
                    variant="flat-danger"
                  >
                    <feather-icon icon="Trash2Icon" size="25" />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group label="Fecha Inicio" :label-for="'lh-fecha-inicio' + index">
                  <b-input-group class="input-group-merge">
                    <b-form-datepicker
                      :min="currentDate"
                      :id="'lh-fecha-inicio' + index"
                      type="date"
                      placeholder="Fecha Inicio"
                      v-model="item.fechaInicio"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Fecha Fin" :label-for="'lh-fecha-fin' + index">
                  <b-input-group class="input-group-merge">
                    <b-form-datepicker
                      :min="currentDate"
                      :id="'lh-fecha-fin' + index"
                      type="date"
                      placeholder="Fecha Fin"
                      v-model="item.fechaFin"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Hora Inicio" :label-for="'lh-hora-inicio' + index">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      :id="'lh-hora-inicio' + index"
                      type="time"
                      placeholder="Hora Inicio"
                      v-model="item.horaInicio"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="Hora Fin" :label-for="'lh-hora-fin' + index">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      :id="'lh-hora-fin' + index"
                      type="time"
                      placeholder="Hora Fin"
                      v-model="item.horaFin"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-list-group-item>
        </transition-group>
      </draggable>
    </b-col>

    <b-col cols="12">
      <bodegas-sectores
        :bodegas="bodegas"
        :element="element"
        :sectores="sectores"
      />
    </b-col>

    <b-col cols="12">
      <b-button
        class="mr-1 mt-2"
        @click="savedata"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-col>
  </b-row>
</template>


<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormFile,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import Acciones from "./Acciones.vue";
import draggable from "vuedraggable";
import BodegasSectores from "./BodegasSectores.vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    vSelect,
    BSpinner,
    Acciones,
    BFormFile,
    draggable,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    BodegasSectores,
    ToastificationContent,
    BFormDatepicker
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      imageSRC: null,
      tipoFiltrado: "Sectores",
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
      currentDate: new Date()
    };
  },
  beforeMount() {
    this.element.opciones = null;
    this.element.items.forEach((i) => {
      if (!i.opciones) i.opciones = { estilo: 1 };
      i.loadingImage = false;
    });
  },
  methods: {
    lastID() {
      return Math.max.apply(
        Math,
        this.element.items.map((o) => o.id)
      );
    },
    dimensionImagen(item) {
      if (item.opciones.estilo === 1) return "Imagen [900 x 900]";
      else return "Imagen (1280, 720)";
    },
    agregarItem(e) {
      e && e.preventDefault();
      this.element.items.push({
        id: this.lastID() + 1,
        activo: true,
        accion: "NONE",
        opciones: { estilo: 1 },
        loadingImage: false,
      });
    },
    eliminarItem(index) {
      this.element.items.splice(index, 1);
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://trade.hemonline.com/html5/Resources/Themes/Default/English/Images/t2/BodyBanner.jpg";
      }
    },
    encodeImageFile(element, index) {
      const self = this;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          self.uploadImage(srcData, index);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    uploadImage(imagenSRC, index) {
      const self = this;
      self.element.items[index].loadingImage = true;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: imagenSRC,
            folder: 'banners',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.element.items[index].loadingImage = false;
          if (res.data && res.data.length > 0) {
            self.element.items[index].imagen = res.data[0].url;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          self.element.items[index].loadingImage = false;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    savedata(e) {
      e.preventDefault();
      let i = 0;
      this.element.items.forEach((item) => {
        item.orden = i;
        i++;
      });
      this.callback(this.element);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>