<template>
  <div>
    <b-alert variant="danger" :show="data === null && !loadingData">
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        No se encontró datos de elementos de home, intente nuevamente mas tarde
      </div>
    </b-alert>

    <b-overlay :show="loadingData" rounded="sm">
      <div v-if="data != null">
        <b-row>
          <b-col cols="12" md="5">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Elementos de Home</b-card-title>
                <b-button @click="createElement" variant="primary" :disabled="saving">
                  Crear
                </b-button>
              </b-card-header>

              <b-card-body>
                <b-row>
                  <b-col cols="12">
                    <b-form-radio-group id="vi-tipo" size="sm" class="mb-2" buttons v-model="tipoHome"
                      @change="filterData" :options="['Main', 'Market', 'Foodie', 'Plus']"
                      button-variant="outline-primary" />
                  </b-col>
                  <b-col cols="12" class="mb-2" style="display: flex">
                    <b-form-radio-group id="vi-tipo" size="sm" buttons v-model="estadoElements" @change="filterData"
                      :options="['Activos', 'Inactivos']" button-variant="outline-primary" />
                    <v-select label="nombre" class="select-size-sm f1 ml-1" v-model="filterSector" :reduce="(s) => s.id"
                      :options="data.sectores" placeholder="Sector" @option:selected="filterData" />
                  </b-col>
                  <!-- draggable -->
                  <b-col v-if="elements !== null" cols="12">
                    <div :key="index" v-for="(tipo, index) in elements">
                      <h4 class="my-1" v-if="tipoHome === 'Main'">
                        {{ tipo.tag }}
                      </h4>
                      <draggable tag="ul" class="list-group list-group-flush cursor-move" v-model="tipo.elements"
                        @change="onChangeOrder">
                        <transition-group type="transition" name="flip-list">
                          <b-list-group-item tag="li" v-for="element in tipo.elements" :key="element.id"
                            @click="setElementSelected(element)" :style="elementSelected !== null &&
                              elementSelected.id === element.id
                              ? {
                                backgroundColor: 'rgba(4, 168, 44, 0.2)',
                              }
                              : null
                              ">
                            <div class="d-flex">
                              <div class="ml-25">
                                <b-card-text class="font-weight-bold mb-0">
                                  <h6 class="transaction-title">
                                    {{ element.titulo }}
                                  </h6>
                                </b-card-text>
                                <b-badge v-if="element.activo" class="mr-1" variant="light-primary">Activo</b-badge>
                                <b-badge v-else class="mr-1" variant="light-danger">Inactivo</b-badge>
                                <b-badge variant="light-secondary">{{
                                  tipoText(element.tipo)
                                }}</b-badge>
                              </div>
                            </div>
                          </b-list-group-item>
                        </transition-group>
                      </draggable>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="12" md="7">
            <b-card class="card-transaction" no-body v-if="elementSelected !== null">
              <b-card-header>
                <b-card-title>{{
                  elementSelected.id === 0
                    ? "Crear Elemento de Home"
                    : "Modificar Elemento de Home"
                }}</b-card-title>

              </b-card-header>

              <b-card-body>
                <b-row>
                  <b-col cols="12">
                    <div class="switch-container mb-1">
                      <b-form-group label-for="vi-activo">
                        <b-form-checkbox id="vi-activo" class="custom-control-primary" switch inline
                          v-model="elementSelected.activo">
                          Activo
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group label-for="vi-main">
                        <b-form-checkbox id="vi-main" class="custom-control-primary" switch inline
                          v-model="elementSelected.main">
                          Home Principal
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group class="mb-0" label-for="vi-modo-prueba">
                        <b-form-checkbox id="vi-modo-prueba" class="custom-control-primary" switch inline
                          v-model="elementSelected.modoPrueba">
                          Modo prueba
                        </b-form-checkbox>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Tipo" label-for="vi-tipo">
                      <v-select id="vi-tipo" label="titulo" v-model="elementSelected.tipo" :reduce="(e) => e.code"
                        :options="tipos" :clearable="false" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <banner v-if="elementSelected.tipo === 'BANNER'" :element="elementSelected" :bodegas="data.bodegas"
                      :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <slide-banner v-else-if="elementSelected.tipo === 'SLIDE_BANNERS'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData"
                      :refreshNotification="refreshUiElements" />
                    <productos v-else-if="elementSelected.tipo === 'PRODUCTOS'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <categoria v-else-if="elementSelected.tipo === 'CATEGORIA'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <productos-recientes v-else-if="elementSelected.tipo === 'PRODUCTOS_RECIENTES'"
                      :element="elementSelected" :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores"
                      :callback="saveData" />
                    <tiendas-destacadas v-else-if="elementSelected.tipo === 'TIENDAS_DESTACADAS'"
                      :element="elementSelected" :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores"
                      :callback="saveData" />
                    <alerta v-else-if="elementSelected.tipo === 'ALERT'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <categorias-destacadas v-else-if="
                      elementSelected.tipo === 'CATEGORIAS_DESTACADAS'
                    " :element="elementSelected" :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores"
                      :callback="saveData" />
                    <cuadricula v-else-if="elementSelected.tipo === 'GRID'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <cuenta-regresiva v-else-if="elementSelected.tipo === 'CUENTA_REGRESIVA'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <lista-horizontal v-else-if="elementSelected.tipo === 'HORIZONTAL_LIST'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <banner-categoria v-else-if="elementSelected.tipo === 'BANNER_CATEGORIA'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <boton-comisariato v-else-if="elementSelected.tipo === 'BANNER_COMISARIATO'"
                      :element="elementSelected" :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores"
                      :callback="saveData" />
                    <categorias v-else-if="elementSelected.tipo === 'CATEGORIAS'" :element="elementSelected"
                      :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores" :callback="saveData" />
                    <productos-destacados v-else-if="
                      elementSelected.tipo === 'PRODUCTOS_DESTACADOS'
                    " :element="elementSelected" :bodegas="data.bodegas" :loading="loading" :sectores="data.sectores"
                      :callback="saveData" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div style="text-align: center; margin: 45vh 0" v-else></div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BAlert,
  BButton,
  BOverlay,
  BSpinner,
  BCardText,
  BCardBody,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";

import Alerta from "./componentes/Alerta.vue";
import Banner from "./componentes/Banner.vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import Categoria from "./componentes/Categoria.vue";
import Productos from "./componentes/Productos.vue";
import Categorias from "./componentes/Categorias.vue";
import Cuadricula from "./componentes/Cuadricula.vue";
import SlideBanner from "./componentes/SlideBannerV2.vue";
import ListaHorizontal from "./componentes/ListaHorizontal.vue";
import CuentaRegresiva from "./componentes/CuentaRegresiva.vue";
import BannerCategoria from "./componentes/BannerCategoria.vue";
import { getAuthToken } from "@/auth/utils";
import BotonComisariato from "./componentes/BotonComisariato.vue";
import TiendasDestacadas from "./componentes/TiendasDestacadas.vue";
import ProductosRecientes from "./componentes/ProductosRecientes.vue";
import ProductosDestacados from "./componentes/ProductosDestacados.vue";
import CategoriasDestacadas from "./componentes/CategoriasDestacadas.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BBadge,
    BAlert,
    Banner,
    Alerta,
    vSelect,
    BButton,
    BSpinner,
    BOverlay,
    Productos,
    Categoria,
    draggable,
    BCardBody,
    BCardText,
    Cuadricula,
    BFormInput,
    BFormGroup,
    BCardTitle,
    Categorias,
    BCardHeader,
    BInputGroup,
    SlideBanner,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    CuentaRegresiva,
    ListaHorizontal,
    BannerCategoria,
    BotonComisariato,
    TiendasDestacadas,
    BInputGroupPrepend,
    ProductosRecientes,
    ProductosDestacados,
    CategoriasDestacadas,
    ToastificationContent,
  },
  data() {
    return {
      data: null,
      stats: null,
      tipos: [
        { titulo: "Botón Mi Comisariato", code: "BANNER_COMISARIATO" },
        { titulo: "Banner", code: "BANNER" },
        { titulo: "Carrusel de Banners", code: "SLIDE_BANNERS" },
        { titulo: "Lista horizontal", code: "HORIZONTAL_LIST" },
        { titulo: "Cuadricula", code: "GRID" },
        { titulo: "Cuenta Regresiva", code: "CUENTA_REGRESIVA" },
        { titulo: "Alerta", code: "ALERT" },
        { titulo: "Categoría", code: "CATEGORIA" },
        { titulo: "Categorías", code: "CATEGORIAS" },
        { titulo: "Categorías Destacadas", code: "CATEGORIAS_DESTACADAS" },
        { titulo: "Tiendas Destacadas", code: "TIENDAS_DESTACADAS" },
        { titulo: "Productos", code: "PRODUCTOS" },
        { titulo: "Productos Destacados", code: "PRODUCTOS_DESTACADOS" },
        { titulo: "Productos Recientes", code: "PRODUCTOS_RECIENTES" },
      ],
      saving: false,
      loading: false,
      elements: [],
      tipoHome: "Main",
      tiposMain: [],
      loadingData: true,
      filterSector: null,
      tipoComercio: "MISC",
      estadoElements: "Activos",
      elementSelected: null,
    };
  },
  mounted() {
    this.getUIElemetsData();
  },
  methods: {
    getUIElemetsData() {
      return new Promise((resolve, reject) => {
        this.loadingData = true;
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/GetUIElements",
            body: JSON.stringify({
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.data = res.data;
              this.filterData();
              resolve();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.error = e.message;
            reject();
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    createElement() {
      this.elementSelected = null;
      this.$nextTick(() => {
        this.elementSelected = {
          id: 0,
          tipo: "BANNER",
          items: [],
          titulo: "",
          activo: false,
          bodegas: [],
          sectores: [],
          comercio: "MARKET",
        };
      });
    },
    filterData() {
      this.elements = null;
      this.$nextTick(() => {
        let isMain = false;
        const comercios = [];
        switch (this.tipoHome) {
          case "Main":
            isMain = true;
            this.tipoComercio = "MISC";
            comercios.push({ tag: "Market", code: "MARKET" });
            comercios.push({ tag: "Foodie", code: "EATS" });
            comercios.push({ tag: "Plus", code: "OTROS_COMERCIOS" });
            break;
          case "Market":
            this.tipoComercio = "MARKET";
            comercios.push({ tag: "Market", code: "MARKET" });
            break;
          case "Foodie":
            this.tipoComercio = "EATS";
            comercios.push({ tag: "Foodie", code: "EATS" });
            break;
          case "Plus":
            this.tipoComercio = "OTROS_COMERCIOS";
            comercios.push({ tag: "Plus", code: "OTROS_COMERCIOS" });
            break;
          default:
            break;
        }
        this.elements = comercios.map((c) => ({
          tag: c.tag,
          elements: this.data.elements
            .filter(
              (e) =>
                e.main === isMain &&
                e.activo === (this.estadoElements === "Activos") &&
                e.comercio === c.code &&
                (this.filterSector === null ||
                  e.sectores.some((s) => s.id === this.filterSector)) &&
                e.tipo !== "MENU" &&
                e.tipo !== "BANNER_CATEGORIA"
            )
            .sort((a, b) => a.orden - b.orden),
        }));
      });
    },
    setElementSelected(element) {
      const aux = JSON.parse(JSON.stringify(element));
      if (aux.items)
        aux.items.sort((a, b) => {
          if (a.orden > b.orden) return 1;
          if (a.orden < b.orden) return -1;
          return 0;
        });
      this.elementSelected = null;
      this.$nextTick(() => {
        this.elementSelected = aux;
      });
    },
    saveData(elementOriginal) {
      const element = JSON.parse(JSON.stringify(elementOriginal));
      this.loading = true;
      if (element.items && element.items.length > 0) {
        element.items.forEach(item => {
          if (item.accion === "TIENDA_EATS" || item.accion === "TIENDA_OC") {
            if (typeof item.listAccionID === 'object') {
              item.listAccionID = item.listAccionID.join(",");
            }
            if (typeof item.listAccionID === 'string' &&
              (item.listAccionID === '[]' || item.listAccionID === 'NaN')
            ) {
              item.listAccionID = "";
            }
            delete item.accionID;
          } else {
            delete item.listAccionID;
          }
        });
      }
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/SetUIElements",
          body: JSON.stringify({
            empresa: 2,
            element: element,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.getUIElemetsData().then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text:
                    this.elementSelected.id === 0
                      ? "Se creo el elementoUI correctamente."
                      : "Se actualizo correctamente el elementoUI.",
                  title: "Éxito",
                  variant: "success",
                },
              });
              if (this.elementSelected.id === 0) {
                this.elementSelected.id = res.data.iDtemp;
                this.setElementSelected(
                  this.data.find(e => e.id === res.data.iDtemp)
                );
              } else {
                this.setElementSelected(
                  this.data.find(e => e.id === this.elementSelected.id)
                );
              }
            });
            this.rebuildCache();
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              icon: "AlertTriangleIcon",
              text:
                this.elementSelected.id === 0
                  ? `Ocurrió un error al Crear el elementoUI (${e.message}).`
                  : `Ocurrió un error al Actualizar el elementoUI (${e.message}).`,
              title: "Error",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refreshUiElements() {
      this.getUIElemetsData();
    },
    onChangeOrder(event) {
      setTimeout(() => {
        const elementsIDs = [];
        this.elements.forEach((c) => {
          c.elements.forEach((e) => {
            elementsIDs.push(e.id);
          });
        });
        this.$http
          .post(this.$store.state.app.middlewareURL, {
            path: "/Administracion/SetUIElementsOrder",
            body: JSON.stringify({
              sort: elementsIDs,
              tokenSesion: getAuthToken(),
            }),
          })
          .then((res) => {
            if (res.data.bOk) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: "CheckIcon",
                  text: "Se actualizo correctamente el orden del elemento.",
                  title: "Éxito",
                  variant: "success",
                },
              });
              this.rebuildCache();
            } else {
              throw new Error(res.data.mensaje);
            }
          })
          .catch((e) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: "AlertTriangleIcon",
                text: e.message,
                title: "Error",
                variant: "danger",
              },
            });
          });
      }, 100);
    },
    tipoText(tipo) {
      const type = this.tipos.find((t) => t.code === tipo);
      if (type) return type.titulo;
      else return tipo;
    },
    async rebuildCache() {
      await new Promise((resolve, reject) => {
        this.$http
          .get(`${this.$store.state.app.socketAPIURL}/cache-stats`)
          .then((res) => {
            this.stats = res.data;
            resolve();
          })
          .catch((e) => {
            reject(e);
          });
      });
      this.stats.keyList.forEach((sKey) => {
        const key = JSON.parse(sKey);
        if (
          key.path === "/Customer/AppHome" &&
          key.body.tipo === this.tipoComercio &&
          !key.body.menu
        ) {
          key.body.refreshCache = true;
          this.$http.post(this.$store.state.app.nodeMiddleware, { ...key, tokenSesion: getAuthToken(), });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.list-group-item {
  transition: all 1s;
}

.f1 {
  flex: 1;
}

.switch-container {
  border: solid 1px #CCCCCC;
  display: flex;
  padding: 1em;
  box-shadow: #e1e1e1 0px 2px 3px 0px;
  border-radius: 6px;
  flex-direction: column;
}
</style>
