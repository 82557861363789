import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    methods:{
        showNotificationOk() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Exito",
                    icon: "CheckIcon",
                    text: "Requerimiento Completado",
                    variant: "success",
                },
            });
        },
        showNotificationError() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    text: "No se pudo completar con el requerimieno",
                    icon: "AlertTriangleIcon",
                    title: "Error",
                    variant: "danger",
                },
            });
        }
    }
}