var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Titulo",
      "label-for": "vi-titulo"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-titulo",
      "placeholder": "Titulo"
    },
    model: {
      value: _vm.element.titulo,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "titulo", $$v);
      },
      expression: "element.titulo"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de Comercio",
      "label-for": "vi-tipo-comercio"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo-comercio",
      "label": "titulo",
      "reduce": function reduce(comercio) {
        return comercio.code;
      },
      "options": _vm.tiposComercio
    },
    on: {
      "option:selected": _vm.onChangeComercio
    },
    model: {
      value: _vm.element.comercio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "comercio", $$v);
      },
      expression: "element.comercio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "vi-fecha-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.currentDate,
      "id": "vi-fecha-inicio",
      "type": "date",
      "placeholder": "FechaInicio",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.element.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaInicio", $$v);
      },
      expression: "element.fechaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "vi-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.currentDate,
      "id": "vi-fecha-fin",
      "type": "date",
      "placeholder": "Fecha Fin",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.element.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaFin", $$v);
      },
      expression: "element.fechaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": "vi-hora-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-hora-inicio",
      "type": "time",
      "placeholder": "Hora Inicio"
    },
    model: {
      value: _vm.element.horaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaInicio", $$v);
      },
      expression: "element.horaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": "vi-hora-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-hora-fin",
      "type": "time",
      "placeholder": "Hora Fin"
    },
    model: {
      value: _vm.element.horaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaFin", $$v);
      },
      expression: "element.horaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-2",
    attrs: {
      "cols": "12"
    }
  }, [_vm.loadingImagen ? _c('div', {
    staticStyle: {
      "height": "70%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('b-spinner', {
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('div', [_vm.element.opciones.imagen !== null ? _c('b-img', {
    attrs: {
      "src": _vm.urlResized(_vm.element.opciones.imagen),
      "fluid": "",
      "alt": "Responsive image"
    }
  }) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Imagen [1200 x 1200]",
      "label-for": "vi-file-in"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "vi-file-in",
      "accept": ".jpg, .png"
    },
    on: {
      "change": _vm.encodeImageFile
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Categoria",
      "label-for": "vi-categoria"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-categoria",
      "label": "nombre",
      "uid": "id",
      "options": _vm.categorias,
      "loading": _vm.loadingCategorias,
      "clearable": false,
      "filterable": true
    },
    on: {
      "input": _vm.getCategoriasData
    },
    model: {
      value: _vm.element.opciones.categoria,
      callback: function callback($$v) {
        _vm.$set(_vm.element.opciones, "categoria", $$v);
      },
      expression: "element.opciones.categoria"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('bodegas-sectores', {
    attrs: {
      "bodegas": _vm.bodegas,
      "element": _vm.element,
      "sectores": _vm.sectores
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }