<template>
  <b-row>
    <b-col cols="12" md="4">
      <div
        v-if="loadingImage"
        style="
          height: 70%;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <b-spinner style="width: 3rem; height: 3rem" label="Loading..." />
      </div>
      <div v-else>
        <b-img
          alt="Responsive image"
          v-if="element.opciones.imagen !== null"
          :src="urlResized(element.opciones.imagen)"
          fluid
        />
      </div>
      <b-form-group label="Imagen [500 x 500]" label-for="'vi-file-in'">
        <b-form-file
          id="'vi-file-in'"
          accept=".jpg, .png"
          @change="encodeImageFile"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12" md="8">
      <b-form-group label="Tipo de Comercio" label-for="vi-tipo-comercio">
        <v-select
          id="vi-tipo-comercio"
          label="titulo"
          v-model="element.comercio"
          :reduce="(comercio) => comercio.code"
          :options="tiposComercio"
        />
      </b-form-group>

      <b-form-group
        label="Nombre Interno(No se muestra en App)"
        label-for="vi-titulo"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-titulo"
            v-model="element.titulo"
            placeholder="Titulo"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group label="Color de Fondo" label-for="vi-backgroud-color">
        <b-form-input
          v-model="element.opciones.colorBackground"
          id="vi-backgroud-color"
          type="color"
          placeholder="Seleccione color"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="vi-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-inicio"
            type="date"
            placeholder="FechaInicio"
            v-model="element.fechaInicio"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-fin"
            type="date"
            placeholder="Fecha Fin"
            v-model="element.fechaFin"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-inicio"
            type="time"
            placeholder="Hora Inicio"
            v-model="element.horaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="vi-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-fin"
            type="time"
            placeholder="Hora Fin"
            v-model="element.horaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-card-header>
        <b-card-title>Textos</b-card-title>
        <b-button variant="primary" class="mr-1" @click="agregarItem">
          Agregar
        </b-button>
      </b-card-header>
      <draggable
        v-model="element.items"
        class="list-group list-group-flush cursor-move"
        tag="ul"
      >
        <transition-group type="transition" name="flip-list">
          <b-list-group-item
            v-for="(item, index) in element.items"
            :key="item.id"
            tag="li"
          >
            <b-row>
              <b-col cols="12" md="2">
                <b-form-group label="Activo" :label-for="'vi-activo' + index">
                  <b-form-checkbox
                    :id="'vi-activo' + index"
                    name="check-button"
                    class="custom-control-primary"
                    switch
                    v-model="item.activo"
                  />
                </b-form-group>
                <b-form-group
                  label="Es boton"
                  :label-for="'vi-is-button' + index"
                >
                  <b-form-checkbox
                    :id="'vi-is-button' + index"
                    name="check-button"
                    class="custom-control-primary"
                    switch
                    v-model="item.opciones.isButton"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="8">
                <b-form-group label="Texto" :label-for="'vi-texto' + index">
                  <b-form-input
                    :id="'vi-texto' + index"
                    v-model="item.nombre"
                    placeholder="Texto"
                  />
                </b-form-group>

                <b-row>
                  <b-col cols="12" :md="item.opciones.isButton ? 6 : 12">
                    <b-form-group
                      label="Color del Texto"
                      :label-for="'vi-color' + index"
                    >
                      <b-form-input
                        :id="'vi-color' + index"
                        type="color"
                        v-model="item.opciones.colorForeground"
                        placeholder="Color"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col v-if="item.opciones.isButton" cols="12" md="6">
                    <b-form-group
                      label="Color de fondo"
                      :label-for="'vi-color-' + index"
                    >
                      <b-form-input
                        :id="'vi-color-' + index"
                        type="color"
                        v-model="item.opciones.colorBackground"
                        placeholder="Color"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <acciones
                  v-if="item.opciones.isButton"
                  :item="item"
                  :comercio="element.comercio"
                />
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group
                  label="Eliminar"
                  :label-for="'vi-eliminar' + index"
                >
                  <b-button
                    :id="'vi-eliminar' + index"
                    class="btn-icon"
                    @click="eliminarItem(index)"
                    variant="flat-danger"
                  >
                    <feather-icon icon="Trash2Icon" size="25" />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row> </b-list-group-item></transition-group
      ></draggable>
    </b-col>

    <b-col cols="12">
      <bodegas-sectores
        :bodegas="bodegas"
        :element="element"
        :sectores="sectores"
      />
    </b-col>

    <b-col cols="12">
      <b-button
        class="mr-1 mt-2"
        @click="savedata"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-col>
  </b-row>
</template>


<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormFile,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BCardHeader,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import Acciones from "./Acciones.vue";
import draggable from "vuedraggable";
import BodegasSectores from "./BodegasSectores.vue";
import { getAuthToken } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    vSelect,
    Acciones,
    BSpinner,
    BFormFile,
    draggable,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BodegasSectores,
    BFormRadioGroup,
    ToastificationContent,
    BFormDatepicker
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      tiendas: [],
      imageSRC: null,
      tiendasOC: [],
      tiendasEats: [],
      loadingImage: false,
      tipoFiltrado: "Sectores",
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
      currentDate: new Date()
    };
  },
  beforeMount() {
    if (this.element.id === 0) {
      this.element.opciones = {
        imagen: null,
        colorBackground: "#88e0b1",
      };
      this.element.items = [];
    }
  },
  methods: {
    selectAll() {
      if (this.tipoFiltrado === "Sectores")
        this.element.sectores = this.sectores;
      else this.element.bodegas = this.bodegas;
    },
    lastID() {
      return Math.max.apply(
        Math,
        this.element.items.map((o) => o.id)
      );
    },
    agregarItem(e) {
      e && e.preventDefault();
      this.element.items.push({
        id: this.lastID() + 1,
        nombre: "",
        activo: true,
        accion: "NONE",
        opciones: {
          isButton: false,
          colorForeground: "#ffffff",
          colorBackground: "#88e0b1",
        },
      });
    },
    eliminarItem(index) {
      this.element.items.splice(index, 1);
    },
    urlResized(urlOriginal) {
      try {
        const url = new URL(urlOriginal);
        url.pathname = "/fit-in/200x200" + url.pathname;
        return url.href;
      } catch (error) {
        return "https://mrconfeccoes.com.br/wp-content/uploads/2018/03/default.jpg";
      }
    },
    encodeImageFile(element) {
      const self = this;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();

        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          self.uploadImage(srcData);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    uploadImage(imagenSRC) {
      this.loadingImage = true;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: imagenSRC,
            folder: 'Alert',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loadingImage = false;
          if (res.data && res.data.length > 0) {
            console.log(res.data[0].url)
            this.element.opciones.imagen = res.data[0].url;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.loadingImage = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    savedata(e) {
      e && e.preventDefault();
      let i = 0;
      this.element.items.forEach((item) => {
        item.orden = i;
        i++;
      });
      this.callback(this.element);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>