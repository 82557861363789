<template>
  <app-collapse type="margin" v-if="item.accion != 'TIENDA_EATS' && item.accion != 'TIENDA_OC'">
    <app-collapse-item title="Donde mostrar" :isVisible="true">
      <b-row>
        <b-col cols="12" md="12">
          <h4>
            <b-badge variant="dark">{{ getSelected }}</b-badge>
          </h4>
          <b-row>
            <b-col cols="12" md="6" class="pr-25">
              <b-button @click="selectAll" variant="primary" block size="sm">
                <small>Seleccionar todo </small>
              </b-button>
            </b-col>
            <b-col cols="12" md="6" class="pl-25">
              <b-button @click="unselectAll" variant="primary" block size="sm">
                <small>Deseleccionar todo</small>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="mt-1" cols="12" md="12" style="display: flex; align-items: start">
          <v-select multiple v-if="getSelected === 'Bodegas'" label="nombre" style="flex: 1" v-model="bodegas_selected"
            :options="bodegas" :closeOnSelect="false" />
          <v-select multiple label="nombre" style="flex: 1" v-else v-model="sectores_selected" :options="sectores"
            :closeOnSelect="false" />
        </b-col>
        <b-col class="mt-1" cols="12" md="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-overlay :show="busy" rounded opacity="0.6" spinner-small spinner-variant="primary"
                style="display: flex; flex-direction: column" @hidden="onHidden">
                <b-button ref="singleItem" :disabled="busy" @click="saveData" variant="primary" block>
                  {{ formType === 1 ? "Actualizar" : "Guardar" }}
                </b-button>
              </b-overlay>
            </b-col>
            <b-col cols="12" md="6">
              <b-button :disabled="busy" @click="callbackDeleteItem(index)" variant="danger" block>
                Eliminar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </app-collapse-item>
  </app-collapse>
  <b-row v-else>
    <b-col cols="12" md="6" >
      <b-button ref="singleItem" :disabled="busy" @click="saveData" variant="primary" block>
        {{ formType === 1 ? "Actualizar" : "Guardar" }}
      </b-button>
    </b-col>
    <b-col cols="12" md="6">
      <b-button :disabled="busy" @click="callbackDeleteItem(index)" variant="danger" block>
        Eliminar
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormCheckbox,
  BFormRadioGroup,
  BBadge,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import mixinNotification from "@mixin/Toastification";
import { mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormGroup,
    AppCollapse,
    BFormCheckbox,
    AppCollapseItem,
    BFormRadioGroup,
    BBadge,
    BOverlay,
  },
  mixins: [mixinNotification],
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    forceMain: {
      type: Boolean,
      default: false,
    },
    /* type: 1 (bodega) - type: 2 (sectores) - type: 0 (ambos) */
    type: {
      type: Number,
      default: 0,
    },
    formType: {
      type: Number,
      required: true,
    },
    refreshNotification: {
      type: Function,
      required: true,
    },
    callbackDeleteItem: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      busy: false,
      bodegas_selected: this.item.bodegas,
      sectores_selected: this.item.sectores,
    };
  },
  computed: {
    getSelected() {
      switch (this.type) {
        case 2:
          return "Sectores";
        default:
          return "Bodegas";
      }
    },
  },
  watch: {
    bodegas_selected(newVal) {
      this.item.bodegas = newVal;
    },
    sectores_selected(newVal) {
      this.item.sectores = newVal;
    },
  },
  beforeMount() {
    if (this.forceMain) this.element.main = true;
  },
  methods: {
    onHidden() {
      this.$refs.singleItem.focus();
    },
    selectAll() {
      this.$nextTick(() => {
        switch (this.type) {
          case 2:
            this.sectores_selected = this.sectores;
          default:
            this.bodegas_selected = this.bodegas;
        }
      });
    },
    unselectAll() {
      switch (this.type) {
        case 2:
          this.sectores_selected = [];
        default:
          this.bodegas_selected = [];
      }
    },
    getBodegasSectores() {
      switch (this.type) {
        case 2:
          return {
            bodegas: null,
            sectores: this.item.accion != "TIENDA_EATS" && this.item.accion != "TIENDA_OC" ? this.sectores_selected.map((i) => i.id) : null,
          };
        default:
          return {
            bodegas: this.bodegas_selected.map((i) => i.id),
            sectores: null,
          };
      }
    },
    async updateItem() {
      try {
        const bodegas_sectores = this.getBodegasSectores();
        const item = {
          ...this.item,
          ...bodegas_sectores,
        };
        const response = await this.updateItemElement(item);
        if (response.bOk) {
          this.showNotificationOk();
          this.refreshNotification();
        } else {
          this.showNotificationError();
        }
      } catch (error) {
        this.showNotificationError();
      }
    },
    async saveData() {
      // formType === 1 ? "Actualizar" : "Guardar"
      this.busy = true;
      if (this.formType === 1) {
        await this.updateItem();
      } else {
        await this.createItem();
      }
      this.busy = false;
    },
    async createItem() {
      try {
        const bodegas_sectores = this.getBodegasSectores();
        const data = {
          horaInicio: this.item.horaInicio,
          horaFin: this.item.horaFin,
          fechaInicio: this.item.fechaInicio,
          fechaFin: this.item.fechaFin,
          elementID: this.element.id,
          descripcion: this.item.descripcion ?? "",
          activo: this.item.activo,
          accionID: this.item.accionID ?? 0,
          accion: this.item.accion,
          imagen: this.item.imagen,
          nombre: this.item.nombre ?? "",
          orden: this.index,
          opciones: null,
          ...bodegas_sectores,
        };
        const response = await this.createUIItem(data);
        if (response.bOk) {
          this.showNotificationOk();
          this.refreshNotification();
        } else {
          this.showNotificationError();
        }
      } catch (error) {
        this.showNotificationError();
      }
    },
    ...mapActions({
      updateItemElement: "contenido/setBannerCarouselAction",
      createUIItem: "contenido/createItemAction",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
