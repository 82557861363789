var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de Comercio",
      "label-for": "vi-tipo-comercio"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo-comercio",
      "label": "titulo",
      "reduce": function reduce(comercio) {
        return comercio.code;
      },
      "options": _vm.tiposComercio
    },
    model: {
      value: _vm.element.comercio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "comercio", $$v);
      },
      expression: "element.comercio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nombre Interno(No se muestra en App)",
      "label-for": "vi-titulo"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-titulo",
      "placeholder": "Titulo"
    },
    model: {
      value: _vm.element.titulo,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "titulo", $$v);
      },
      expression: "element.titulo"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Color de Fondo",
      "label-for": "vi-backgroud-color"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-backgroud-color",
      "type": "color",
      "placeholder": "Seleccione color"
    },
    model: {
      value: _vm.element.opciones.colorBackground,
      callback: function callback($$v) {
        _vm.$set(_vm.element.opciones, "colorBackground", $$v);
      },
      expression: "element.opciones.colorBackground"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha de fin",
      "label-for": "vi-fecha-fin"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "id": "vi-fecha-fin",
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i'
      }
    },
    model: {
      value: _vm.element.opciones.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.element.opciones, "endTime", $$v);
      },
      expression: "element.opciones.endTime"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "vi-fecha-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.currentDate,
      "id": "vi-fecha-inicio",
      "type": "date",
      "placeholder": "FechaInicio"
    },
    model: {
      value: _vm.element.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaInicio", $$v);
      },
      expression: "element.fechaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "vi-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.currentDate,
      "id": "vi-fecha-fin",
      "type": "date",
      "placeholder": "Fecha Fin"
    },
    model: {
      value: _vm.element.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaFin", $$v);
      },
      expression: "element.fechaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": "vi-hora-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-hora-inicio",
      "type": "time",
      "placeholder": "Hora Inicio"
    },
    model: {
      value: _vm.element.horaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaInicio", $$v);
      },
      expression: "element.horaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": "vi-hora-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-hora-fin",
      "type": "time",
      "placeholder": "Hora Fin"
    },
    model: {
      value: _vm.element.horaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaFin", $$v);
      },
      expression: "element.horaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Textos")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.agregarItem
    }
  }, [_vm._v(" Agregar ")])], 1), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.element.items,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "items", $$v);
      },
      expression: "element.items"
    }
  }, [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.element.items, function (item, index) {
    return _c('b-list-group-item', {
      key: item.id,
      attrs: {
        "tag": "li"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "12",
        "md": "2"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Activo",
        "label-for": 'vi-activo' + index
      }
    }, [_c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "id": 'vi-activo' + index,
        "switch": ""
      },
      model: {
        value: item.activo,
        callback: function callback($$v) {
          _vm.$set(item, "activo", $$v);
        },
        expression: "item.activo"
      }
    })], 1), _c('b-form-group', {
      attrs: {
        "label": "Mostrar al finalizar",
        "label-for": 'vi-is-button' + index
      }
    }, [_c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "id": 'vi-is-button' + index,
        "switch": ""
      },
      model: {
        value: item.opciones.isEndTag,
        callback: function callback($$v) {
          _vm.$set(item.opciones, "isEndTag", $$v);
        },
        expression: "item.opciones.isEndTag"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Texto",
        "label-for": 'vi-texto' + index
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'vi-texto' + index,
        "placeholder": "Texto"
      },
      model: {
        value: item.nombre,
        callback: function callback($$v) {
          _vm.$set(item, "nombre", $$v);
        },
        expression: "item.nombre"
      }
    })], 1), _c('b-form-group', {
      attrs: {
        "label": "Color del Texto",
        "label-for": 'vi-color' + index
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'vi-color' + index,
        "type": "color",
        "placeholder": "Color"
      },
      model: {
        value: item.opciones.colorForeground,
        callback: function callback($$v) {
          _vm.$set(item.opciones, "colorForeground", $$v);
        },
        expression: "item.opciones.colorForeground"
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "2"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Eliminar",
        "label-for": 'vi-eliminar' + index
      }
    }, [_c('b-button', {
      staticClass: "btn-icon",
      attrs: {
        "id": 'vi-eliminar' + index,
        "variant": "flat-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.eliminarItem(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Trash2Icon",
        "size": "25"
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('bodegas-sectores', {
    attrs: {
      "bodegas": _vm.bodegas,
      "element": _vm.element,
      "sectores": _vm.sectores
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }