<template>
  <div>
    <b-row>
      <b-col cols="12" md="12">
        <div style="display: flex;">
          <b-form-group>
            <b-form-checkbox :id="'vi-activo-' + index" name="check-button" class="custom-control-primary" switch inline
              v-model="item.activo">
              Activo
            </b-form-checkbox>
          </b-form-group>

        </div>
        <div v-if="item.loadingImage" style="height: 70%; display: flex; align-items: center; justify-content: center;">
          <b-spinner style="width: 3rem; height: 3rem" label="Loading..." />
        </div>
        <div v-else>
          <b-img v-if="item.imagen !== null" :src="item.imagen" fluid />
        </div>
        <b-form-group :label="element.main ? 'Imagen [1200 x 1200]' : 'Imagen [1600 x 900]'"
          :label-for="'vi-file-in-' + index">
          <b-form-file :id="'vi-file-in-' + index" accept=".jpg, .png" @change="encodeImageFile($event, index)" />
        </b-form-group>
        <acciones :item="item" :comercio="element.comercio" />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Fecha Inicio" :label-for="'vi-item-fecha-inicio-' + index">
          <b-input-group class="input-group-merge">
            <b-form-datepicker :min="getCurrentDate" :id="'vi-item-fecha-inicio-' + index" local="es"
              v-model="item.fechaInicio" placeholder="Fecha Inicio" :date-format-options="{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Fecha Fin" :label-for="'vi-item-fecha-fin-' + index">
          <b-input-group class="input-group-merge">
            <b-form-datepicker :min="getCurrentDate" :id="'vi-item-fecha-fin-' + index" type="date"
              placeholder="Fecha Fin" v-model="item.fechaFin" :date-format-options="{
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Hora Inicio" :label-for="'vi-item-hora-inicio-' + index">
          <b-input-group class="input-group-merge">
            <b-form-input :id="'vi-item-hora-inicio-' + index" type="time" placeholder="Hora Inicio"
              locale="en"
              v-model="item.horaInicio" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Hora Fin" :label-for="'vi-hora-fin-' + index">
          <b-input-group class="input-group-merge">
            <b-form-input :id="'vi-hora-fin-' + index" type="time" placeholder="Hora Fin" v-model="item.horaFin" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <bodegas-sectores :item="item" :bodegas="bodegas" :element="element" :sectores="sectores"
          :type="getTipoComercio" :formType="formType" :index="index" :refreshNotification="refreshNotification"
          :callback-delete-item="(i) => eliminarItem(i)" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormFile,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormDatepicker,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import Acciones from "./Acciones.vue";
import BodegasSectores from "./BodegasSectoresV2.vue";
import { getAuthToken } from "@/auth/utils";
import { mapActions } from "vuex";
import mixinNotification from "@mixin/Toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    vSelect,
    BButton,
    BSpinner,
    Acciones,
    BFormFile,
    draggable,
    BFormInput,
    BCardTitle,
    BFormGroup,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormDatepicker,
    BFormRadioGroup,
    BodegasSectores,
    ToastificationContent,
  },
  data() {
    return {
      currentDate: new Date(),
    };
  },
  mixins: [mixinNotification],
  props: {
    index: {
      type: Number,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    formType: {
      // 0 - CREACION, 1 - EDICION
      type: Number,
      required: false,
      default: 0,
    },
    refreshNotification: {
      type: Function,
      required: true,
    }
  },
  computed: {
    getTipoComercio() {
      switch (this.element.comercio) {
        case "MARKET":
          return 1;
        default:
          return 2;
      }
    },
    getCurrentDate() {
      return this.currentDate;
    },
  },
  methods: {
    encodeImageFile(element, index) {
      const self = this;
      const filesSelected = element.currentTarget.files;
      if (filesSelected.length > 0) {
        const fileToLoad = filesSelected[0];
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          self.uploadImage(srcData, index);
        };
        fileReader.readAsDataURL(fileToLoad);
      }
    },
    async deleteItem() {
      const data = {
        id: this.item.id
      };
      const response = await this.deleteUIItem(data);
      if (response.bOk) {
        this.showNotificationOk();
        this.refreshNotification();
      } else {
        this.showNotificationError();
      }
    },
    async eliminarItem(index) {
      if (this.formType === 1) {
        await this.deleteItem();
      }
      this.element.items.splice(index, 1);
    },
    uploadImage(imagenSRC, index) {
      const self = this;
      self.element.items[index].loadingImage = true;
      this.$http
        .post(this.$store.state.app.middlewareAWSURL, {
          path: "/Administracion/AWSUpload",
          body: JSON.stringify({
            files: imagenSRC,
            folder: 'Banners',
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          self.element.items[index].loadingImage = false;
          if (res.data && res.data.length > 0) {
            self.element.items[index].imagen = res.data[0].url;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          self.element.items[index].loadingImage = false;
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              text: `Ocurrió un error al subir la imagen (${e.message}).`,
              variant: "danger",
            },
          });
        });
    },
    ...mapActions({
      deleteUIItem: "contenido/deleteItemAction",
    }),
  },
};
</script>
