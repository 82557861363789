var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-collapse', [_c('app-collapse-item', {
    attrs: {
      "title": "Donde mostrar"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Donde mostrar",
      "label-for": "vi-tipo"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "vi-tipo",
      "buttons": "",
      "options": ['Bodegas', 'Sectores'],
      "button-variant": "outline-primary"
    },
    model: {
      value: _vm.tipoFiltrado,
      callback: function callback($$v) {
        _vm.tipoFiltrado = $$v;
      },
      expression: "tipoFiltrado"
    }
  })], 1)], 1), !_vm.forceMain ? _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Home Principal",
      "label-for": "vi-main"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-main",
      "switch": ""
    },
    model: {
      value: _vm.element.main,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "main", $$v);
      },
      expression: "element.main"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    staticStyle: {
      "display": "flex",
      "align-items": "start"
    },
    attrs: {
      "cols": "12"
    }
  }, [_vm.tipoFiltrado === 'Bodegas' ? _c('v-select', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "id": "vi-tipo-bodegas",
      "label": "nombre",
      "multiple": "",
      "options": _vm.bodegas
    },
    model: {
      value: _vm.element.bodegas,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "bodegas", $$v);
      },
      expression: "element.bodegas"
    }
  }) : _c('v-select', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "id": "vi-tipo-sectores",
      "label": "nombre",
      "multiple": "",
      "options": _vm.sectores
    },
    model: {
      value: _vm.element.sectores,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "sectores", $$v);
      },
      expression: "element.sectores"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('b-button', {
    staticClass: "ml-1 mb-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.selectAll
    }
  }, [_vm._v(" Seleccionar todo ")]), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.unselectAll
    }
  }, [_vm._v(" Deseleccionar todo ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }