<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-form-group label="Titulo" label-for="vi-titulo">
        <b-input-group class="input-group-merge">
          <b-form-input id="vi-titulo" v-model="element.titulo" placeholder="Titulo" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Tipo de Comercio" label-for="vi-tipo-comercio">
        <v-select id="vi-tipo-comercio" label="titulo" v-model="element.comercio" :reduce="(comercio) => comercio.code"
          :options="tiposComercio" />
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="vi-element-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker :min="getCurrentDate" id="vi-element-fecha-inicio" local="es" v-model="element.fechaInicio"
            placeholder="FechaInicio" :date-format-options="{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="vi-element-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker :min="getCurrentDate" id="vi-element-fecha-fin" type="date" placeholder="Fecha Fin"
            v-model="element.fechaFin" :date-format-options="{
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input id="vi-hora-inicio" type="time" placeholder="Hora Inicio" v-model="element.horaInicio"
            locale="en" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="vi-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input id="vi-hora-fin" type="time" placeholder="Hora Fin" v-model="element.horaFin" locale="en" />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" class="text-center">
      <b-button class="mr-1 mt-2" @click="savedata" variant="primary" :disabled="loading">
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-col>
    <b-col cols="12">
      <b-card-header>
        <b-card-title>Banners</b-card-title>
        <b-button variant="primary" class="mr-1" @click="agregarItem">
          Nuevo Banner
        </b-button>
      </b-card-header>
      <draggable tag="ul" class="list-group list-group-flush cursor-move" v-model="element.items">
        <transition-group type="transition" name="flip-list">
          <b-list-group-item tag="li" v-for="(item, index) in element.items" :key="index + 1">
            <item-carrusel-banner :item="item" :element="element" :bodegas="bodegas" :sectores="sectores" :index="index"
              :formType="item.id <= 0 ? 0 : 1" :refreshNotification="refreshNotification" />
          </b-list-group-item>
        </transition-group>
      </draggable>
    </b-col>
  </b-row>
</template>

<script>
import {
  // BImg,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormFile,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormDatepicker,
  BFormRadioGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Acciones from "./Acciones.vue";
import draggable from "vuedraggable";
import BodegasSectores from "./BodegasSectoresV2.vue";
import ItemCarruselBanner from "./ItemCarruselBanner.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import mixinNotification from "@mixin/Toastification";
import { mapActions } from "vuex";

export default {
  components: {
    // BImg,
    BRow,
    BCol,
    vSelect,
    BButton,
    BSpinner,
    Acciones,
    BFormFile,
    draggable,
    BFormInput,
    BCardTitle,
    BFormGroup,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormDatepicker,
    BFormRadioGroup,
    BodegasSectores,
    ToastificationContent,
    ItemCarruselBanner,
  },
  mixins: [mixinNotification],
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
    refreshNotification: {
      type: Function,
      required: true,
    }
  },
  data() {
    return {
      data: null,
      imageSRC: null,
      currentDate: new Date(),
      tipoFiltrado: "Sectores",
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
    };
  },
  computed: {
    getCurrentDate() {
      return this.currentDate;
    },
  },
  methods: {
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    agregarItem(e) {
      e && e.preventDefault();
      this.element.items.push({
        id: 0,
        activo: true,
        accion: "NONE",
        loadingImage: false,
      });
    },
    async updateElement() {
      let i = 0;
      this.element.items.forEach((item) => {
        item.orden = i;
        i++;
      });
      const items = this.element.items.map((i) => ({
        id: i.id,
        orden: i.orden,
      }));
      const sectores = this.element.sectores.map((i) => i.id);
      const bodegas = this.element.bodegas.map((i) => i.id);
      let element = _.cloneDeep(this.element);
      element.items = items;
      element.sectores = sectores;
      element.bodegas = bodegas;
      const response = await this.updateElementOrderItem(element);
      if (response.bOk) {
        this.showNotificationOk();
      } else {
        this.showNotificationError();
      }
      this.refreshNotification();
    },
    async savedata(e) {
      e.preventDefault();
      if (this.element.id <= 0) {
        // Cuando se quiere crear un elemento, este va a contener todas las bodegas y sectores
        this.element.bodegas = this.bodegas;
        this.element.sectores = this.sectores;
        // this.callback(this.element);
        await this.createElement();
      } else {
        await this.updateElement();
      }
    },
    async createElement() {
      const response = await this.createUIElement(this.element);
      if (response.bOk) {
        this.showNotificationOk();
      } else {
        this.showNotificationError();
      }
      this.refreshNotification();
    },
    ...mapActions({
      createUIElement: "contenido/createElementAction",
      updateElementOrderItem: "contenido/updateElementOrderItemAction",
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>
