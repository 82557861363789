var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": 'vi-activo-' + _vm.index,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.item.activo,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "activo", $$v);
      },
      expression: "item.activo"
    }
  }, [_vm._v(" Activo ")])], 1)], 1), _vm.item.loadingImage ? _c('div', {
    staticStyle: {
      "height": "70%",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('b-spinner', {
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('div', [_vm.item.imagen !== null ? _c('b-img', {
    attrs: {
      "src": _vm.item.imagen,
      "fluid": ""
    }
  }) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.element.main ? 'Imagen [1200 x 1200]' : 'Imagen [1600 x 900]',
      "label-for": 'vi-file-in-' + _vm.index
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": 'vi-file-in-' + _vm.index,
      "accept": ".jpg, .png"
    },
    on: {
      "change": function change($event) {
        return _vm.encodeImageFile($event, _vm.index);
      }
    }
  })], 1), _c('acciones', {
    attrs: {
      "item": _vm.item,
      "comercio": _vm.element.comercio
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": 'vi-item-fecha-inicio-' + _vm.index
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.getCurrentDate,
      "id": 'vi-item-fecha-inicio-' + _vm.index,
      "local": "es",
      "placeholder": "Fecha Inicio",
      "date-format-options": {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    },
    model: {
      value: _vm.item.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "fechaInicio", $$v);
      },
      expression: "item.fechaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": 'vi-item-fecha-fin-' + _vm.index
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.getCurrentDate,
      "id": 'vi-item-fecha-fin-' + _vm.index,
      "type": "date",
      "placeholder": "Fecha Fin",
      "date-format-options": {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }
    },
    model: {
      value: _vm.item.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "fechaFin", $$v);
      },
      expression: "item.fechaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": 'vi-item-hora-inicio-' + _vm.index
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": 'vi-item-hora-inicio-' + _vm.index,
      "type": "time",
      "placeholder": "Hora Inicio",
      "locale": "en"
    },
    model: {
      value: _vm.item.horaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "horaInicio", $$v);
      },
      expression: "item.horaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": 'vi-hora-fin-' + _vm.index
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": 'vi-hora-fin-' + _vm.index,
      "type": "time",
      "placeholder": "Hora Fin"
    },
    model: {
      value: _vm.item.horaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "horaFin", $$v);
      },
      expression: "item.horaFin"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('bodegas-sectores', {
    attrs: {
      "item": _vm.item,
      "bodegas": _vm.bodegas,
      "element": _vm.element,
      "sectores": _vm.sectores,
      "type": _vm.getTipoComercio,
      "formType": _vm.formType,
      "index": _vm.index,
      "refreshNotification": _vm.refreshNotification,
      "callback-delete-item": function callbackDeleteItem(i) {
        return _vm.eliminarItem(i);
      }
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }