var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.accionUnica ? _c('b-form-group', {
    attrs: {
      "label": "Accion al hacer Tap",
      "label-for": "vi-accion"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-accion",
      "label": "titulo",
      "options": _vm.acciones,
      "reduce": function reduce(accion) {
        return accion.code;
      },
      "clearable": false
    },
    on: {
      "option:selected": _vm.onChangeAction
    },
    model: {
      value: _vm.item.accion,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "accion", $$v);
      },
      expression: "item.accion"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'CATEGORIA_EATS' ? _c('b-form-group', {
    attrs: {
      "label": "Categoría Foodie",
      "label-for": "vi-categoria-tienda-accion"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-categoria-tienda-accion",
      "label": "nombre",
      "reduce": function reduce(categoria) {
        return categoria.id;
      },
      "options": _vm.categoriasEats,
      "loading": _vm.loadingCategorias,
      "clearable": false,
      "filterable": true
    },
    model: {
      value: _vm.item.accionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "accionID", $$v);
      },
      expression: "item.accionID"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'CATEGORIA_OC' ? _c('b-form-group', {
    attrs: {
      "label": "Categoría Plus",
      "label-for": "vi-categoria-tienda-accion"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-categoria-tienda-accion",
      "label": "nombre",
      "reduce": function reduce(categoria) {
        return categoria.id;
      },
      "options": _vm.categoriasOC,
      "loading": _vm.loadingCategorias,
      "clearable": false,
      "filterable": true
    },
    model: {
      value: _vm.item.accionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "accionID", $$v);
      },
      expression: "item.accionID"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'CATEGORIA_MARKET' ? _c('b-form-group', {
    attrs: {
      "label": "Categoría MiComisariato",
      "label-for": "vi-categoria-market-accion"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-categoria-market-accion",
      "label": "nombre",
      "reduce": function reduce(categoria) {
        return categoria.id;
      },
      "options": _vm.categoriasMarket,
      "loading": _vm.loadingCategorias,
      "clearable": false,
      "filterable": true
    },
    model: {
      value: _vm.item.accionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "accionID", $$v);
      },
      expression: "item.accionID"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'TIENDA_EATS' ? _c('b-form-group', {
    attrs: {
      "label": "Tiendas Foodie",
      "label-for": "vi-marca-tienda-accion-foodie"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-marca-tienda-accion-foodie",
      "label": "nombre",
      "reduce": function reduce(store) {
        return store.id;
      },
      "options": _vm.stores,
      "loading": _vm.loadingStores,
      "clearable": false,
      "filterable": true,
      "multiple": true
    },
    model: {
      value: _vm.item.listAccionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "listAccionID", $$v);
      },
      expression: "item.listAccionID"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'TIENDA_OC' ? _c('b-form-group', {
    attrs: {
      "label": "Tienda Plus",
      "label-for": "vi-marca-tienda-accion-plus"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-marca-tienda-accion-plus",
      "label": "nombre",
      "reduce": function reduce(store) {
        return store.id;
      },
      "options": _vm.stores,
      "loading": _vm.loadingStores,
      "clearable": false,
      "filterable": true,
      "multiple": true
    },
    model: {
      value: _vm.item.listAccionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "listAccionID", $$v);
      },
      expression: "item.listAccionID"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'PRODUCTO_OC' || _vm.item.accion === 'PRODUCTO_EATS' || _vm.item.accion === 'PRODUCTO_MARKET' ? _c('b-form-group', {
    attrs: {
      "label": "Producto MiComisariato",
      "label-for": "vi-producto-accion"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-producto-accion",
      "label": "nombre",
      "reduce": function reduce(producto) {
        return producto.id;
      },
      "options": _vm.productos,
      "loading": _vm.loadingProductos,
      "clearable": false,
      "filterable": true
    },
    on: {
      "search": _vm.searchProductData
    },
    model: {
      value: _vm.item.accionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "accionID", $$v);
      },
      expression: "item.accionID"
    }
  })], 1) : _vm._e(), _vm.item.accion === 'TIENDA_MARCA' ? _c('b-form-group', {
    attrs: {
      "label": "Tienda Foodie",
      "label-for": "vi-marca-accion"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-marca-accion",
      "label": "nombreMarca",
      "reduce": function reduce(marca) {
        return marca.idMarca;
      },
      "options": _vm.marcas,
      "loading": _vm.loadingMarcas,
      "clearable": false,
      "filterable": true
    },
    model: {
      value: _vm.item.accionID,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "accionID", $$v);
      },
      expression: "item.accionID"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }