var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    class: {
      'open': _vm.visible
    },
    attrs: {
      "no-body": ""
    },
    on: {
      "mouseenter": _vm.collapseOpen,
      "mouseleave": _vm.collapseClose
    }
  }, [_c('b-card-header', {
    class: {
      'collapsed': !_vm.visible
    },
    attrs: {
      "aria-expanded": _vm.visible ? 'true' : 'false',
      "aria-controls": _vm.collapseItemID,
      "role": "tab",
      "data-toggle": "collapse"
    },
    on: {
      "click": function click($event) {
        return _vm.updateVisible(!_vm.visible);
      }
    }
  }, [_vm._t("header", [_c('span', {
    staticClass: "lead collapse-title",
    style: {
      textDecoration: _vm.active ? 'none' : 'line-through'
    }
  }, [_vm._v(_vm._s(_vm.title))])])], 2), _c('b-collapse', {
    attrs: {
      "id": _vm.collapseItemID,
      "accordion": _vm.accordion,
      "role": "tabpanel"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('b-card-body', [_vm._t("default")], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }