var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Titulo",
      "label-for": "vi-titulo"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "vi-titulo",
      "placeholder": "Titulo"
    },
    model: {
      value: _vm.element.titulo,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "titulo", $$v);
      },
      expression: "element.titulo"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo de Comercio",
      "label-for": "vi-tipo-comercio"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo-comercio",
      "label": "titulo",
      "reduce": function reduce(comercio) {
        return comercio.code;
      },
      "options": _vm.tiposComercio
    },
    model: {
      value: _vm.element.comercio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "comercio", $$v);
      },
      expression: "element.comercio"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Inicio",
      "label-for": "lhg-fecha-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.currentDate,
      "id": "lhg-fecha-inicio",
      "type": "date",
      "placeholder": "Fecha Inicio",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.element.fechaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaInicio", $$v);
      },
      expression: "element.fechaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fecha Fin",
      "label-for": "lhg-fecha-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-datepicker', {
    attrs: {
      "min": _vm.currentDate,
      "id": "lhg-fecha-fin",
      "type": "date",
      "placeholder": "Fecha Fin",
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }
    },
    model: {
      value: _vm.element.fechaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "fechaFin", $$v);
      },
      expression: "element.fechaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Inicio",
      "label-for": "lhg-hora-inicio"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "lhg-hora-inicio",
      "type": "time",
      "placeholder": "Hora Inicio"
    },
    model: {
      value: _vm.element.horaInicio,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaInicio", $$v);
      },
      expression: "element.horaInicio"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Hora Fin",
      "label-for": "lhg-hora-fin"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "id": "lhg-hora-fin",
      "type": "time",
      "placeholder": "Hora Fin"
    },
    model: {
      value: _vm.element.horaFin,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "horaFin", $$v);
      },
      expression: "element.horaFin"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Banners")]), _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.agregarItem
    }
  }, [_vm._v(" Agregar ")])], 1), _c('draggable', {
    staticClass: "list-group list-group-flush cursor-move",
    attrs: {
      "tag": "ul"
    },
    model: {
      value: _vm.element.items,
      callback: function callback($$v) {
        _vm.$set(_vm.element, "items", $$v);
      },
      expression: "element.items"
    }
  }, [_c('transition-group', {
    attrs: {
      "type": "transition",
      "name": "flip-list"
    }
  }, _vm._l(_vm.element.items, function (item, index) {
    return _c('b-list-group-item', {
      key: item.id,
      attrs: {
        "tag": "li"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('b-form-radio-group', {
      staticClass: "my-1",
      attrs: {
        "id": "vi-style",
        "size": "sm",
        "buttons": "",
        "options": [{
          text: 'Estilo 1',
          value: 1
        }, {
          text: 'Estilo 2',
          value: 2
        }],
        "text-field": "text",
        "value-field": "value",
        "button-variant": "outline-primary"
      },
      model: {
        value: item.opciones.estilo,
        callback: function callback($$v) {
          _vm.$set(item.opciones, "estilo", $$v);
        },
        expression: "item.opciones.estilo"
      }
    }), item.loadingImage ? _c('div', {
      staticStyle: {
        "height": "70%",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('b-spinner', {
      staticStyle: {
        "width": "3rem",
        "height": "3rem"
      },
      attrs: {
        "label": "Loading..."
      }
    })], 1) : _c('div', [item.imagen !== null ? _c('b-img', {
      attrs: {
        "src": _vm.urlResized(item.imagen),
        "fluid": "",
        "alt": "Responsive image"
      }
    }) : _vm._e()], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Texto",
        "label-for": 'vi-texto' + index
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": 'vi-texto' + index,
        "placeholder": "Texto"
      },
      model: {
        value: item.nombre,
        callback: function callback($$v) {
          _vm.$set(item, "nombre", $$v);
        },
        expression: "item.nombre"
      }
    })], 1), _c('b-form-group', {
      attrs: {
        "label": _vm.dimensionImagen(item),
        "label-for": 'vi-file-in' + index
      }
    }, [_c('b-form-file', {
      attrs: {
        "id": 'vi-file-in' + index,
        "accept": ".jpg, .png"
      },
      on: {
        "change": function change($event) {
          return _vm.encodeImageFile($event, index);
        }
      }
    })], 1), _c('acciones', {
      attrs: {
        "item": item,
        "comercio": _vm.element.comercio
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "2"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Activo",
        "label-for": 'vi-activo' + index
      }
    }, [_c('b-form-checkbox', {
      staticClass: "custom-control-primary",
      attrs: {
        "id": 'vi-activo' + index,
        "switch": ""
      },
      model: {
        value: item.activo,
        callback: function callback($$v) {
          _vm.$set(item, "activo", $$v);
        },
        expression: "item.activo"
      }
    })], 1), _c('b-form-group', {
      attrs: {
        "label": "Borrar",
        "label-for": 'vi-delete' + index
      }
    }, [_c('b-button', {
      staticClass: "btn-icon",
      attrs: {
        "id": 'vi-delete' + index,
        "variant": "flat-danger"
      },
      on: {
        "click": function click($event) {
          return _vm.eliminarItem(index);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Trash2Icon",
        "size": "25"
      }
    })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Fecha Inicio",
        "label-for": 'lh-fecha-inicio' + index
      }
    }, [_c('b-input-group', {
      staticClass: "input-group-merge"
    }, [_c('b-form-datepicker', {
      attrs: {
        "min": _vm.currentDate,
        "id": 'lh-fecha-inicio' + index,
        "type": "date",
        "placeholder": "Fecha Inicio",
        "date-format-options": {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
      },
      model: {
        value: item.fechaInicio,
        callback: function callback($$v) {
          _vm.$set(item, "fechaInicio", $$v);
        },
        expression: "item.fechaInicio"
      }
    })], 1)], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Fecha Fin",
        "label-for": 'lh-fecha-fin' + index
      }
    }, [_c('b-input-group', {
      staticClass: "input-group-merge"
    }, [_c('b-form-datepicker', {
      attrs: {
        "min": _vm.currentDate,
        "id": 'lh-fecha-fin' + index,
        "type": "date",
        "placeholder": "Fecha Fin",
        "date-format-options": {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
      },
      model: {
        value: item.fechaFin,
        callback: function callback($$v) {
          _vm.$set(item, "fechaFin", $$v);
        },
        expression: "item.fechaFin"
      }
    })], 1)], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Hora Inicio",
        "label-for": 'lh-hora-inicio' + index
      }
    }, [_c('b-input-group', {
      staticClass: "input-group-merge"
    }, [_c('b-form-input', {
      attrs: {
        "id": 'lh-hora-inicio' + index,
        "type": "time",
        "placeholder": "Hora Inicio"
      },
      model: {
        value: item.horaInicio,
        callback: function callback($$v) {
          _vm.$set(item, "horaInicio", $$v);
        },
        expression: "item.horaInicio"
      }
    })], 1)], 1)], 1), _c('b-col', {
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Hora Fin",
        "label-for": 'lh-hora-fin' + index
      }
    }, [_c('b-input-group', {
      staticClass: "input-group-merge"
    }, [_c('b-form-input', {
      attrs: {
        "id": 'lh-hora-fin' + index,
        "type": "time",
        "placeholder": "Hora Fin"
      },
      model: {
        value: item.horaFin,
        callback: function callback($$v) {
          _vm.$set(item, "horaFin", $$v);
        },
        expression: "item.horaFin"
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('bodegas-sectores', {
    attrs: {
      "bodegas": _vm.bodegas,
      "element": _vm.element,
      "sectores": _vm.sectores
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mr-1 mt-2",
    attrs: {
      "variant": "primary",
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.savedata
    }
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _c('div', [_vm._v("Guardar")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }