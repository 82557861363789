<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col md="6">
          <b-form-group label="Tipo de Comercio" label-for="vi-tipo-comercio">
            <v-select
              id="vi-tipo-comercio"
              label="titulo"
              v-model="element.comercio"
              :reduce="(comercio) => comercio.code"
              :options="tiposComercio"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nombre Interno(No se muestra en App)"
            label-for="vi-titulo"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="vi-titulo"
                v-model="element.titulo"
                placeholder="Titulo"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Color de Fondo" label-for="vi-backgroud-color">
            <b-form-input
              id="vi-backgroud-color"
              type="color"
              v-model="element.opciones.colorBackground"
              placeholder="Seleccione color"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Fecha de fin" label-for="vi-fecha-fin">
            <flat-pickr
              id="vi-fecha-fin"
              class="form-control"
              v-model="element.opciones.endTime"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="vi-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-inicio"
            type="date"
            placeholder="FechaInicio"
            v-model="element.fechaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-fin"
            type="date"
            placeholder="Fecha Fin"
            v-model="element.fechaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-inicio"
            type="time"
            placeholder="Hora Inicio"
            v-model="element.horaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="vi-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-fin"
            type="time"
            placeholder="Hora Fin"
            v-model="element.horaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-card-header>
        <b-card-title>Textos</b-card-title>
        <b-button variant="primary" class="mr-1" @click="agregarItem">
          Agregar
        </b-button>
      </b-card-header>
      <draggable
        tag="ul"
        class="list-group list-group-flush cursor-move"
        v-model="element.items"
      >
        <transition-group type="transition" name="flip-list">
          <b-list-group-item
            tag="li"
            :key="item.id"
            v-for="(item, index) in element.items"
          >
            <b-row>
              <b-col cols="12" md="2">
                <b-form-group label="Activo" :label-for="'vi-activo' + index">
                  <b-form-checkbox
                    :id="'vi-activo' + index"
                    class="custom-control-primary"
                    switch
                    v-model="item.activo"
                  />
                </b-form-group>
                <b-form-group
                  label="Mostrar al finalizar"
                  :label-for="'vi-is-button' + index"
                >
                  <b-form-checkbox
                    :id="'vi-is-button' + index"
                    class="custom-control-primary"
                    switch
                    v-model="item.opciones.isEndTag"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="8">
                <b-form-group label="Texto" :label-for="'vi-texto' + index">
                  <b-form-input
                    :id="'vi-texto' + index"
                    v-model="item.nombre"
                    placeholder="Texto"
                  />
                </b-form-group>

                <b-form-group
                  label="Color del Texto"
                  :label-for="'vi-color' + index"
                >
                  <b-form-input
                    :id="'vi-color' + index"
                    type="color"
                    v-model="item.opciones.colorForeground"
                    placeholder="Color"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group
                  label="Eliminar"
                  :label-for="'vi-eliminar' + index"
                >
                  <b-button
                    :id="'vi-eliminar' + index"
                    class="btn-icon"
                    @click="eliminarItem(index)"
                    variant="flat-danger"
                  >
                    <feather-icon icon="Trash2Icon" size="25" />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row> </b-list-group-item></transition-group
      ></draggable>
    </b-col>

    <b-col cols="12">
      <bodegas-sectores
        :bodegas="bodegas"
        :element="element"
        :sectores="sectores"
      />
    </b-col>

    <b-col cols="12">
      <b-button
        class="mr-1 mt-2"
        @click="savedata"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-col>
  </b-row>
</template>


<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormFile,
  BFormInput,
  BFormGroup,
  BCardTitle,
  BInputGroup,
  BCardHeader,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";

import vSelect from "vue-select";
import draggable from "vuedraggable";
import flatPickr from "vue-flatpickr-component";
import BodegasSectores from "./BodegasSectores.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    vSelect,
    BSpinner,
    flatPickr,
    draggable,
    BFormFile,
    BCardTitle,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    BodegasSectores,
    ToastificationContent,
    BFormDatepicker
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      data: null,
      tiendas: [],
      tiendasEats: [],
      tiendasOC: [],
      imageSRC: null,
      loadingImage: false,
      tipoFiltrado: "Sectores",
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
      currentDate: new Date(),
    };
  },
  beforeMount() {
    if (this.element.id === 0) {
      this.element.opciones = {
        imagen: null,
        endTime: null,
        colorBackground: "#88e0b1",
      };
      this.element.items = [];
    } else {
      if (this.element.opciones.endTime === undefined)
        this.element.opciones.endTime = null;
    }
  },
  methods: {
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    agregarItem(e) {
      e && e.preventDefault();
      this.element.items.push({
        id: this.randomNumber(60000, 120000),
        nombre: "",
        activo: true,
        accion: "NONE",
        opciones: {
          isEndTag: false,
          colorForeground: "#ffffff",
        },
      });
    },
    eliminarItem(index) {
      this.element.items.splice(index, 1);
    },
    savedata(e) {
      e.preventDefault();
      let i = 0;
      this.element.items.forEach((item) => {
        item.orden = i;
        i++;
      });
      this.callback(this.element);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>