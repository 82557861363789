<template>
  <div>
    <b-form-group v-if="!accionUnica" label="Accion al hacer Tap" label-for="vi-accion">
      <v-select id="vi-accion" label="titulo" :options="acciones" v-model="item.accion"
        :reduce="(accion) => accion.code" :clearable="false" @option:selected="onChangeAction" />
    </b-form-group>
    <b-form-group label="Categoría Foodie" label-for="vi-categoria-tienda-accion"
      v-if="item.accion === 'CATEGORIA_EATS'">
      <v-select id="vi-categoria-tienda-accion" label="nombre" v-model="item.accionID"
        :reduce="(categoria) => categoria.id" :options="categoriasEats" :loading="loadingCategorias" :clearable="false"
        :filterable="true" />
    </b-form-group>
    <b-form-group label="Categoría Plus" label-for="vi-categoria-tienda-accion" v-if="item.accion === 'CATEGORIA_OC'">
      <v-select id="vi-categoria-tienda-accion" label="nombre" v-model="item.accionID"
        :reduce="(categoria) => categoria.id" :options="categoriasOC" :loading="loadingCategorias" :clearable="false"
        :filterable="true" />
    </b-form-group>
    <b-form-group label="Categoría MiComisariato" label-for="vi-categoria-market-accion"
      v-if="item.accion === 'CATEGORIA_MARKET'">
      <v-select id="vi-categoria-market-accion" label="nombre" v-model="item.accionID"
        :reduce="(categoria) => categoria.id" :options="categoriasMarket" :loading="loadingCategorias"
        :clearable="false" :filterable="true" />
    </b-form-group>
    <b-form-group label="Tiendas Foodie" label-for="vi-marca-tienda-accion-foodie" v-if="item.accion === 'TIENDA_EATS'">
      <v-select id="vi-marca-tienda-accion-foodie" label="nombre" v-model="item.listAccionID"
        :reduce="(store) => store.id" :options="stores" :loading="loadingStores" :clearable="false" :filterable="true"
        :multiple="true" />
    </b-form-group>
    <b-form-group label="Tienda Plus" label-for="vi-marca-tienda-accion-plus" v-if="item.accion === 'TIENDA_OC'">
      <v-select id="vi-marca-tienda-accion-plus" label="nombre" v-model="item.listAccionID"
        :reduce="(store) => store.id" :options="stores" :loading="loadingStores" :clearable="false" :filterable="true"
        :multiple="true" />
    </b-form-group>
    <b-form-group label="Producto MiComisariato" label-for="vi-producto-accion" v-if="
      item.accion === 'PRODUCTO_OC' ||
      item.accion === 'PRODUCTO_EATS' ||
      item.accion === 'PRODUCTO_MARKET'
    ">
      <v-select id="vi-producto-accion" label="nombre" v-model="item.accionID" :reduce="(producto) => producto.id"
        @search="searchProductData" :options="productos" :loading="loadingProductos" :clearable="false"
        :filterable="true" />
    </b-form-group>
    <b-form-group label="Tienda Foodie" label-for="vi-marca-accion" v-if="item.accion === 'TIENDA_MARCA'">
      <v-select id="vi-marca-accion" label="nombreMarca" v-model="item.accionID" :reduce="(marca) => marca.idMarca"
        :options="marcas" :loading="loadingMarcas" :clearable="false" :filterable="true" />
    </b-form-group>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BForm, BInputGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    vSelect,
    BFormGroup,
    BInputGroup,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    comercio: {
      type: String,
      required: true,
    },
    accionUnica: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: "",
      marcas: [],
      stores: [],
      marcasFoodie: [],
      marcasPlus: [],
      productos: [],
      categoriasOC: [],
      seachTimeout: null,
      loadingMarcas: false,
      categoriasEats: [],
      loadingStores: false,
      loadingMarcasFoodie: false,
      loadingMarcasPlus: false,
      categoriasMarket: [],
      loadingProductos: false,
      loadingCategorias: false,
      acciones: [
        { titulo: "Nada", code: "NONE" },
        { titulo: "Ir a categoria de MiComisariato", code: "CATEGORIA_MARKET" },
        { titulo: "Ir a categoria de Foodie", code: "CATEGORIA_EATS" },
        { titulo: "Ir a categoria de Plus", code: "CATEGORIA_OC" },
        { titulo: "Ir a tienda de Foodie", code: "TIENDA_EATS" },
        { titulo: "Ir a tienda de Plus", code: "TIENDA_OC" },
        // { titulo: "Ir a un producto MiComisariato", code: "PRODUCTO_MARKET" },
        // { titulo: "Ir a un producto Foodie", code: "PRODUCTO_EATS" },
        // { titulo: "Ir a un producto Plus", code: "PRODUCTO_OC" },
        // { titulo: "Ir a la tienda de la Marca", code: "TIENDA_MARCA" },
      ],
    };
  },
  beforeMount() {
    console.log(this.item);
    if (this.item.accion === "TIENDA_EATS" || this.item.accion === "TIENDA_OC") {
      if (typeof this.item.listAccionID === "string") {
        if (this.item.listAccionID === "") {
          this.item.listAccionID = [];
        } else {
          this.item.listAccionID = this.item.listAccionID.split(",").map(i => parseInt(i));
        }
      }
    }
  },
  mounted() {
    this.onChangeAction({ code: this.item.accion });
  },
  methods: {
    onChangeAction(accion) {
      switch (accion.code) {
        case "PRODUCTO_OC":
        case "PRODUCTO_EATS":
        case "PRODUCTO_MARKET":
          this.searchProductData("", this.item.accionID);
          break;
        case "TIENDA_OC":
          if (this.stores.length === 0) this.getTiendasData();
          break;
        case "TIENDA_EATS":
          if (this.stores.length === 0) this.getTiendasData();
          break;
        case "TIENDA_MARCA":
          if (this.marcas.length === 0) this.getMarcas();
          break;
        case "CATEGORIA_OC":
          if (this.categoriasOC.length === 0)
            this.getCategoriasData("OTROS_COMERCIOS");
          break;
        case "CATEGORIA_EATS":
          if (this.categoriasEats.length === 0) this.getCategoriasData("EATS");
          break;
        case "CATEGORIA_MARKET":
          if (this.categoriasMarket.length === 0)
            this.getCategoriasData("MARKET");
          break;

        default:
          break;
      }
    },
    getTiendasData() {
      this.loadingStores = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetStores",
          body: JSON.stringify({
            empresa: 2,
            tokenSesion: getAuthToken(),
          }),
        }).then((res) => {
          if (res.data.bOk) {
            this.stores = res.data.stores;
          } else {
            this.error = res.data.mensaje;
          }
        }).catch((e) => {
          this.error = e.message;
        }).finally(() => {
          this.loadingStores = false;
        });
    },
    getMarcasFoodie() {
      this.loadingMarcasFoodie = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Marcas",
          body: JSON.stringify({
            opt: "TE",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.marcasFoodie = res.data.lsMarcas;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })

      this.loadingMarcasFoodie = false;
    },
    getMarcasPlus() {
      this.loadingMarcasPLus = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Marcas",
          body: JSON.stringify({
            opt: "TOC",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.marcasPlus = res.data.lsMarcas;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })

      this.loadingMarcasPLus = false;
    },
    getCategoriasData(comercio) {
      this.loadingCategorias = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetCategories",
          body: JSON.stringify({
            empresa: 2,
            comercio: comercio,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            switch (comercio) {
              case "MARKET":
                this.categoriasMarket = res.data.categorias;
                break;
              case "EATS":
                this.categoriasEats = res.data.categorias;
                break;
              case "OTROS_COMERCIOS":
                this.categoriasOC = res.data.categorias;
                break;
              default:
                break;
            }
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingCategorias = false;
        });
    },
    getMarcas() {
      this.loadingMarcas = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/Marcas",
          body: JSON.stringify({
            opt: "V",
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          if (res.data.bOk) {
            this.marcas = res.data.lsMarcas;
          } else {
            throw new Error(res.data.mensaje);
          }
        })
        .catch((e) => {
          this.error = e.message;
        })
        .finally(() => {
          this.loadingMarcas = false;
        });
    },
    searchProductData(query, id) {
      if (query.length > 3 || typeof id === "number") {
        const comercio =
          this.item.accion === "PRODUCTO_MARKET"
            ? "MARKET"
            : self.item.accion === "PRODUCTO_EATS"
              ? "EATS"
              : "OTROS_COMERCIOS";
        this.loadingProductos = true;
        clearTimeout(this.seachTimeout);
        this.seachTimeout = setTimeout(() => {
          this.$http
            .post(this.$store.state.app.middlewareURL, {
              path: "/Administracion/SearchProduct",
              body: JSON.stringify({
                id: id,
                query: query,
                empresa: 2,
                comercio: comercio,
                tokenSesion: getAuthToken(),
              }),
            })
            .then((res) => {
              if (res.data.bOk) {
                this.productos = res.data.productos;
              } else {
                throw new Error(res.data.mensaje);
              }
            })
            .catch((e) => {
              this.error = e.message;
            })
            .finally(() => {
              this.loadingProductos = false;
            });
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>