var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.data === null && !_vm.loadingData
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" No se encontró datos de elementos de home, intente nuevamente mas tarde ")])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loadingData,
      "rounded": "sm"
    }
  }, [_vm.data != null ? _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('b-card', {
    staticClass: "card-transaction",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v("Elementos de Home")]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.saving
    },
    on: {
      "click": _vm.createElement
    }
  }, [_vm._v(" Crear ")])], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mb-2",
    attrs: {
      "id": "vi-tipo",
      "size": "sm",
      "buttons": "",
      "options": ['Main', 'Market', 'Foodie', 'Plus'],
      "button-variant": "outline-primary"
    },
    on: {
      "change": _vm.filterData
    },
    model: {
      value: _vm.tipoHome,
      callback: function callback($$v) {
        _vm.tipoHome = $$v;
      },
      expression: "tipoHome"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "id": "vi-tipo",
      "size": "sm",
      "buttons": "",
      "options": ['Activos', 'Inactivos'],
      "button-variant": "outline-primary"
    },
    on: {
      "change": _vm.filterData
    },
    model: {
      value: _vm.estadoElements,
      callback: function callback($$v) {
        _vm.estadoElements = $$v;
      },
      expression: "estadoElements"
    }
  }), _c('v-select', {
    staticClass: "select-size-sm f1 ml-1",
    attrs: {
      "label": "nombre",
      "reduce": function reduce(s) {
        return s.id;
      },
      "options": _vm.data.sectores,
      "placeholder": "Sector"
    },
    on: {
      "option:selected": _vm.filterData
    },
    model: {
      value: _vm.filterSector,
      callback: function callback($$v) {
        _vm.filterSector = $$v;
      },
      expression: "filterSector"
    }
  })], 1), _vm.elements !== null ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.elements, function (tipo, index) {
    return _c('div', {
      key: index
    }, [_vm.tipoHome === 'Main' ? _c('h4', {
      staticClass: "my-1"
    }, [_vm._v(" " + _vm._s(tipo.tag) + " ")]) : _vm._e(), _c('draggable', {
      staticClass: "list-group list-group-flush cursor-move",
      attrs: {
        "tag": "ul"
      },
      on: {
        "change": _vm.onChangeOrder
      },
      model: {
        value: tipo.elements,
        callback: function callback($$v) {
          _vm.$set(tipo, "elements", $$v);
        },
        expression: "tipo.elements"
      }
    }, [_c('transition-group', {
      attrs: {
        "type": "transition",
        "name": "flip-list"
      }
    }, _vm._l(tipo.elements, function (element) {
      return _c('b-list-group-item', {
        key: element.id,
        style: _vm.elementSelected !== null && _vm.elementSelected.id === element.id ? {
          backgroundColor: 'rgba(4, 168, 44, 0.2)'
        } : null,
        attrs: {
          "tag": "li"
        },
        on: {
          "click": function click($event) {
            return _vm.setElementSelected(element);
          }
        }
      }, [_c('div', {
        staticClass: "d-flex"
      }, [_c('div', {
        staticClass: "ml-25"
      }, [_c('b-card-text', {
        staticClass: "font-weight-bold mb-0"
      }, [_c('h6', {
        staticClass: "transaction-title"
      }, [_vm._v(" " + _vm._s(element.titulo) + " ")])]), element.activo ? _c('b-badge', {
        staticClass: "mr-1",
        attrs: {
          "variant": "light-primary"
        }
      }, [_vm._v("Activo")]) : _c('b-badge', {
        staticClass: "mr-1",
        attrs: {
          "variant": "light-danger"
        }
      }, [_vm._v("Inactivo")]), _c('b-badge', {
        attrs: {
          "variant": "light-secondary"
        }
      }, [_vm._v(_vm._s(_vm.tipoText(element.tipo)))])], 1)])]);
    }), 1)], 1)], 1);
  }), 0) : _vm._e()], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_vm.elementSelected !== null ? _c('b-card', {
    staticClass: "card-transaction",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', [_c('b-card-title', [_vm._v(_vm._s(_vm.elementSelected.id === 0 ? "Crear Elemento de Home" : "Modificar Elemento de Home"))])], 1), _c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "switch-container mb-1"
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "vi-activo"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-activo",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.elementSelected.activo,
      callback: function callback($$v) {
        _vm.$set(_vm.elementSelected, "activo", $$v);
      },
      expression: "elementSelected.activo"
    }
  }, [_vm._v(" Activo ")])], 1), _c('b-form-group', {
    attrs: {
      "label-for": "vi-main"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-main",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.elementSelected.main,
      callback: function callback($$v) {
        _vm.$set(_vm.elementSelected, "main", $$v);
      },
      expression: "elementSelected.main"
    }
  }, [_vm._v(" Home Principal ")])], 1), _c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-for": "vi-modo-prueba"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-primary",
    attrs: {
      "id": "vi-modo-prueba",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.elementSelected.modoPrueba,
      callback: function callback($$v) {
        _vm.$set(_vm.elementSelected, "modoPrueba", $$v);
      },
      expression: "elementSelected.modoPrueba"
    }
  }, [_vm._v(" Modo prueba ")])], 1)], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tipo",
      "label-for": "vi-tipo"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "vi-tipo",
      "label": "titulo",
      "reduce": function reduce(e) {
        return e.code;
      },
      "options": _vm.tipos,
      "clearable": false
    },
    model: {
      value: _vm.elementSelected.tipo,
      callback: function callback($$v) {
        _vm.$set(_vm.elementSelected, "tipo", $$v);
      },
      expression: "elementSelected.tipo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.elementSelected.tipo === 'BANNER' ? _c('banner', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'SLIDE_BANNERS' ? _c('slide-banner', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData,
      "refreshNotification": _vm.refreshUiElements
    }
  }) : _vm.elementSelected.tipo === 'PRODUCTOS' ? _c('productos', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'CATEGORIA' ? _c('categoria', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'PRODUCTOS_RECIENTES' ? _c('productos-recientes', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'TIENDAS_DESTACADAS' ? _c('tiendas-destacadas', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'ALERT' ? _c('alerta', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'CATEGORIAS_DESTACADAS' ? _c('categorias-destacadas', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'GRID' ? _c('cuadricula', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'CUENTA_REGRESIVA' ? _c('cuenta-regresiva', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'HORIZONTAL_LIST' ? _c('lista-horizontal', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'BANNER_CATEGORIA' ? _c('banner-categoria', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'BANNER_COMISARIATO' ? _c('boton-comisariato', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'CATEGORIAS' ? _c('categorias', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm.elementSelected.tipo === 'PRODUCTOS_DESTACADOS' ? _c('productos-destacados', {
    attrs: {
      "element": _vm.elementSelected,
      "bodegas": _vm.data.bodegas,
      "loading": _vm.loading,
      "sectores": _vm.data.sectores,
      "callback": _vm.saveData
    }
  }) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _c('div', {
    staticStyle: {
      "text-align": "center",
      "margin": "45vh 0"
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }