<template>
  <b-row>
    <b-col cols="12" md="6">
      <b-form-group label="Titulo" label-for="vi-titulo">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-titulo"
            v-model="element.titulo"
            placeholder="Titulo"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Tipo de Comercio" label-for="vi-tipo-comercio">
        <v-select
          id="vi-tipo-comercio"
          label="titulo"
          v-model="element.comercio"
          :reduce="(comercio) => comercio.code"
          :options="tiposComercio"
          @option:selected="onChangeComercio"
        />
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group label="Categoria" label-for="vi-categoria">
        <v-select
          id="vi-categoria"
          label="nombre"
          v-model="element.opciones.categoria"
          @search="getCategoriasData"
          :reduce="(categoria) => categoria.id"
          :options="categorias"
          :loading="loadingCategorias"
          :filterable="true"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Inicio" label-for="vi-fecha-inicio">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-inicio"
            type="date"
            placeholder="FechaInicio"
            v-model="element.fechaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Fecha Fin" label-for="vi-fecha-fin">
        <b-input-group class="input-group-merge">
          <b-form-datepicker
            :min="currentDate"
            id="vi-fecha-fin"
            type="date"
            placeholder="Fecha Fin"
            v-model="element.fechaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Inicio" label-for="vi-hora-inicio">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-inicio"
            type="time"
            placeholder="Hora Inicio"
            v-model="element.horaInicio"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-group label="Hora Fin" label-for="vi-hora-fin">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="vi-hora-fin"
            type="time"
            placeholder="Hora Fin"
            v-model="element.horaFin"
          />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <bodegas-sectores
        :bodegas="bodegas"
        :element="element"
        :sectores="sectores"
      />
    </b-col>

    <b-col cols="12">
      <b-button
        class="mr-1 mt-2"
        @click="savedata"
        variant="primary"
        :disabled="loading"
      >
        <b-spinner v-if="loading" small label="Loading..." />
        <div v-else>Guardar</div>
      </b-button>
    </b-col>
  </b-row>
</template>


<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BCardHeader,
  BInputGroup,
  BFormCheckbox,
  BListGroupItem,
  BFormRadioGroup,
  BFormDatepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import BodegasSectores from "./BodegasSectores.vue";
import { getAuthToken } from "@/auth/utils";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    vSelect,
    BSpinner,
    BFormInput,
    BFormGroup,
    BCardTitle,
    BCardHeader,
    BInputGroup,
    BFormCheckbox,
    BListGroupItem,
    BFormRadioGroup,
    BodegasSectores,
    BFormDatepicker
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    element: {
      type: Object,
      required: true,
    },
    bodegas: {
      type: Array,
      required: true,
    },
    sectores: {
      type: Array,
      required: true,
    },
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      categorias: [],
      categoriasOC: [],
      tiposComercio: [
        { titulo: "Market", code: "MARKET" },
        { titulo: "Eats", code: "EATS" },
        { titulo: "Otros Comercios", code: "OTROS_COMERCIOS" },
      ],
      tipoFiltrado: "Sectores",
      categoriasEats: [],
      categoriasMarket: [],
      loadingCategorias: false,
      currentDate: new Date(),
    };
  },
  beforeMount() {
    if (!this.element.opciones) {
      this.element.opciones = {
        estilo: 1,
        categoria: 0,
        categoriaPadre: 0,
      };
    }
    this.getCategoriasData(this.element.comercio);
  },
  methods: {
    onChangeComercio(comercio) {
      switch (comercio.code) {
        case "MARKET":
          if (this.categoriasMarket.length === 0)
            this.getCategoriasData("MARKET");
          else this.categorias = this.categoriasMarket;
          break;
        case "EATS":
          if (this.categoriasEats.length === 0) this.getCategoriasData("EATS");
          else this.categorias = this.categoriasEats;
          break;
        case "OTROS_COMERCIOS":
          if (this.categoriasOC.length === 0)
            this.getCategoriasData("OTROS_COMERCIOS");
          else this.categorias = this.categoriasOC;
          break;
        default:
          break;
      }
    },
    getCategoriasData(comercio) {
      this.loadingCategorias = true;
      this.$http
        .post(this.$store.state.app.middlewareURL, {
          path: "/Administracion/GetCategories",
          body: JSON.stringify({
            empresa: 2,
            comercio: comercio,
            tokenSesion: getAuthToken(),
          }),
        })
        .then((res) => {
          this.loadingCategorias = false;
          if (res.data.bOk) {
            switch (comercio) {
              case "MARKET":
                this.categoriasMarket = res.data.categorias;
                break;
              case "EATS":
                this.categoriasEats = res.data.categorias;
                break;
              case "OTROS_COMERCIOS":
                this.categoriasOC = res.data.categorias;
                break;
              default:
                break;
            }
            this.categorias = res.data.categorias;
          } else {
            this.error = res.data.mensaje;
          }
        })
        .catch((e) => {
          this.loadingCategorias = false;
          this.error = e.message;
        });
    },
    savedata(e) {
      e.preventDefault();
      this.callback(this.element);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#vi-tipo label {
  display: flex;
  align-items: center;
}
</style>